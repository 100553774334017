import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import { State } from "data/reducers/state";
import { CoinsHistoryActions } from "data/slices/coinshistory";
import { CoinsHistoryStateProps } from "data/slices/coinshistory/interface";
import keys from "locale/keys";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  COINS_HISTORY_REDEEMED,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from "utils/constants";
import { getOrders } from "utils/mappers/MyOrdersMapper";
import MyOrders from "widgets/dashboard/myorders";

import { Wrapper } from "../styled.components";

const MyOrdersContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isFetching, coinshistory, isError } = useSelector<
    State,
    CoinsHistoryStateProps
  >((state) => state.coinshistory);

  useEffect(() => {
    fetchOrders(DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrders = (page: number, pageSize: number) => {
    dispatch(
      CoinsHistoryActions.request({
        status: COINS_HISTORY_REDEEMED,
        pageSize,
        page,
      }),
    );
  };

  const myorders = getOrders(coinshistory.rows);

  return (
    <>
      <HeaderWithFilter
        title={t(keys.my_orders)}
        showFilter={false}
        showBackButton={false}
      />
      <Divider />
      <Wrapper>
        <MyOrders
          ordersToDisplay={myorders}
          fetchOrders={fetchOrders}
          recordsCount={coinshistory.count}
          isLoading={isFetching}
          isError={isError}
        />
      </Wrapper>
    </>
  );
};

export default MyOrdersContainer;
