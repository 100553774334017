import { UpdateSurveyRequest } from "data/slices/surveylist/interface";
import { isEqual } from "lodash";
import { SURVEY_STATUS } from "utils/constants";

/*
  Precision Sample
  https://panel-staging.surveyspotter.com/survey-redirect?sub_id3=33545tgh567768&surveyid=1080000037&Status=S&survey_complete_date=11/28/2024&gross=2.50&reward=1.80
 
  S,means member completed the Surver preliminarily.
  T,means member failed ot terminated from the Survey.
  Q,means member quota full for the Survey.
  R,means member completed the Survey Preliminary but finally rejected by the Client. Initially when the member completes the survey preliminarily we fire a Pixel with status S and then after 2 to 3 weeks (means,When we have the Updated final complets list from the client side people),if this member is not in the final completed list we fire the pixel with Status R.So always the status "S" indicates that member can get reward and the Status "R" cancels the rewards issued preliminarily(Status=S).
  F,means client approved the Preliminary complete as Final Complete
 
 
  CINT 
  https://panel-staging.surveyspotter.com/survey-redirect?pid=xyz&sur=1234567&l=10&c=1.50&mid=abc&42=53&43=2&45=70130&639=10010000000&hash=RiNPoR2Bu4ZqhGXQrcK6IL2BcRElctks

  Variable	Returned on	                Description
  pid	      Complete, Termination	      Unique respondent identifier created by the supplier.
  mid	      Complete, Termination	      Optional secondary identifier passed in on entry. Value returns blank if not passed in on entry.
  sur	      Complete	                  Completed survey ID.
  c	        Complete	                  Gross payout per complete in USD. This value is before any applicable commissions or fees.
  l	        Complete	                  Median LOI of the completed survey. Note: until the survey has reached 6 completes, the parameter l will be returned as 0.
  [%questionID%]	Complete, Termination	Standard qualification questionID. Precode value returned if data is stored for the respondent. Value returns blank if no data stored. questionIDs may be returned.           
  hash	    Complete, Termination	      HMAC SHA1 hash signature of the entire URL. Used by the supplier to validate whether the URL has been manipulated during redirect.


  Dynata
  https://panel-staging.surveyspotter.com/survey-redirect?disposition=1&status=0&access_key=1234&expiration=2021-10-19T17:48:36.480Z&signature=c156e91013eb715edb2b07df5491497a807206b1796bb7d031505107a9dbdcdf


  name	          disposition	    description
  Unknown/Error	  0	              An unknown state was reached or an error was encountered.
  Complete	      1	              The respondent completed the client survey.
  Disqualified	  2	              The respondent was disqualified.
  Over Quota	    3	              The respondent qualified for a full quota and was terminated.
  Duplicate	      4	              The respondent already participated in the client survey.
                                  Once a respondent is sent into a survey, the same respondent should not be sent back to the same survey, dedupe on start using project_id. Typically, if a respondent hits a conclusion, even if the survey reopens, they will hit a duplicate if they enter again.
                                  For partners with both Registry and Router, Registry and Router traffic needs to be deduped against project_id, Dynata can add project_id to router redirects for partners to then dedupe when sending traffic through registry.
  Quality	        5	              The respondent was disqualified by Dynata due to failing quality checks.
 */

export const isSurveySucccess = (params?: URLSearchParams) => {
  if (!params) {
    return false;
  }

  //precision sample
  if (isPrecisionSample(params)) {
    const status = params?.get("Status")?.toLowerCase();
    console.log(`precision sample -> status --> ${status}`);
    return isPrecisionSampleSuccess(status);
  }

  //cint
  if (isCINT(params)) {
    console.log(`cint -> status --> ${params?.get("c")}`);
    return isCINTSuccess(params); //just check if url has gross payout param
  }

  //dynata
  if (isDynata(params)) {
    const status = params?.get("status")?.toLowerCase();
    console.log(`dynata -> status --> ${status}`);
    return isDynataSuccess(status);
  }

  return false;
};

export const getRequestPayload = (
  userId: string,
  params?: URLSearchParams,
): UpdateSurveyRequest | undefined => {
  if (!params) {
    return undefined;
  }

  //precision sample
  if (isPrecisionSample(params)) {
    const status = params.get("Status")?.toLowerCase();
    const surveyId = params.get("surveyid");
    const surveyStatus = isPrecisionSampleSuccess(status)
      ? SURVEY_STATUS.COMPLETED
      : SURVEY_STATUS.FAILED;
    return createPayload(userId, surveyId, surveyStatus);
  }

  //cint
  //for cint, for now if redirected then treat as success
  if (isCINT(params)) {
    const surveyId = params.get("survey_id");
    return createPayload(userId, surveyId, SURVEY_STATUS.COMPLETED);
  }

  //dynata
  //TODO: need to confirm how to get the survey id in url
  if (isDynata(params)) {
    const status = params?.get("status")?.toLowerCase();
    const surveyId = params.get("surveyId");
    const surveyStatus = isDynataSuccess(status)
      ? SURVEY_STATUS.COMPLETED
      : SURVEY_STATUS.FAILED;
    return createPayload(userId, surveyId, surveyStatus);
  }

  return undefined;
};

const isPrecisionSample = (params?: URLSearchParams) => {
  return params?.has("surveyid");
};

const isCINT = (params?: URLSearchParams) => {
  return params?.has("pid");
};

const isDynata = (params?: URLSearchParams) => {
  return params?.has("signature");
};

const isPrecisionSampleSuccess = (status?: string) => {
  return isEqual(status, "s") || isEqual(status, "f");
};

const isCINTSuccess = (params?: URLSearchParams) => {
  return params?.get("c");
};

const isDynataSuccess = (status?: string) => {
  return isEqual(status, "1");
};

const createPayload = (
  userId: string,
  surveyId: string,
  status: string,
): UpdateSurveyRequest => {
  return {
    status,
    surveyId,
    userId,
  };
};
