import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./interface";

export const WalletDetailsSlice = createSlice({
  name: "walletdetails",
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.walletdetails = action.payload;
    },
    error: (state) => {
      state.isFetching = false;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const { actions: WalletDetailsActions } = WalletDetailsSlice;

export default WalletDetailsSlice.reducer;
