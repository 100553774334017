import DashboardContainer from "containers/dashboard";
import WelcomeContainer from "containers/welcome";
import PrivateRoute from "PrivateRoute";
import { createBrowserRouter, Navigate } from "react-router-dom";
import {
  DASHBOARD_DEFAULT_ROUTE,
  PRELOGIN_INDEX,
  ROUTES,
} from "utils/constants";
import Prelogin from "widgets/prelogin";
import SurveyCompletionRedirection from "widgets/redirection/SurveyCompletionRedirection";
import SurveyInfoRedirection from "widgets/redirection/SurveyInfoRedirection";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate replace to={DASHBOARD_DEFAULT_ROUTE} />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Prelogin index={PRELOGIN_INDEX.LOGIN} />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <Prelogin index={PRELOGIN_INDEX.FORGOT_PASSWORD} />,
  },
  {
    path: ROUTES.CHANGE_PASSWORD,
    element: <Prelogin index={PRELOGIN_INDEX.CHANGE_PASSWORD} />,
  },
  {
    path: ROUTES.SIGNUP,
    element: <Prelogin index={PRELOGIN_INDEX.SIGNUP} />,
  },
  {
    path: ROUTES.SURVEY_REDIRECT,
    element: <SurveyCompletionRedirection />,
  },
  //below redirections are specific to dynata. Instead of success or failure just show info
  {
    path: ROUTES.SURVEY_REDIRECT_OVERQUOTA,
    element: <SurveyInfoRedirection />,
  },
  {
    path: ROUTES.SURVEY_REDIRECT_SECURITY,
    element: <SurveyInfoRedirection />,
  },
  {
    path: ROUTES.SURVEY_REDIRECT_TERMINATED,
    element: <SurveyInfoRedirection />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: ROUTES.WELCOME,
        element: <WelcomeContainer />,
      },
      {
        path: ROUTES.DASHBOARD,
        element: <DashboardContainer />,
      },
    ],
  },
]);

export default routes;
