const en = {
  prelogin_welcome_message: "Paid surveys: Earn money online,Work From Home",
  prelogin_welcome_description:
    "Find coupons, promo codes, deals, and cash back offers to your favorite stores.",
  login: "Login",
  please_enter_details: "Welcome! Please enter your details.",
  email: "Email",
  enter_email: "Enter your email",
  password: "Password",
  enter_password: "Enter password",
  confirm_password: "Confirm Password",
  old_password: "Old Password",
  dont_have_account: "Don’t have an account?",
  forgot_password: "Forgot Password?",
  sign_up: "Sign up",
  sign_up_title: "Sign Up",
  lost_password_help: "Lost password help",
  enter_email_associated:
    "Enter the email address associated with your account ",
  send: "Send",
  start: "Start",
  start_survey: "Start Survey",
  did_you_remember_password: "Did you remember your password?",
  try_logging_in: "Try logging in",
  recovery_email_title: "Password Reset Sent",
  successfully: "Successfully",
  successfully_reset: "Your password has been reset successfully",
  successfully_signup: "Your account has been created successfully",
  account_exists: "Account with this Email already exists.",
  recovery_email_msg:
    "Please check your email for next steps to reset your password.",
  recovery_email_redirection: "Did you remember your password?",
  register: "Register",
  welcome_enter_your_details: "Welcome! Please enter your details.",
  fullname: "Full Name",
  enter_your_name: "Enter your name",
  enter_name: "Enter name",
  enter_first_name: "Enter first name",
  enter_last_name: "Enter last name",
  questionnaire_one_title: "1. When were you born?",
  questionnaire_two_title: "2. What's your gender?",
  questionnaire_three_title: "3. Enter your Postcode.",
  questionnaire_four_title: "4. Your marriage status?",
  passwords_not_matching_validations: "Password not strong enough",
  passwords_not_matching: "Password and confirm password not matching",
  please_enter_username: "Please enter Username",
  please_enter_password: "Please enter Password",
  please_enter_address: "Please enter Address",
  please_enter_ethnicity: "Please select a Ethnicity",
  please_select_gender: "Please select a Gender",
  please_enter_old_password: "Please enter Old Password",
  please_enter_mobile: "Please enter Mobile Number",
  please_enter_confirm_password: "Please enter Confirm Password",
  enter_valid_username: "Please enter valid Username",
  please_enter_fullname: "Please enter Full Name",
  please_enter_firstname: "Please enter First Name",
  please_enter_validname:
    "Input can only contain letters, spaces, hyphens, apostrophes, and an optional period.",
  please_enter_description: "Please enter Description",
  please_enter_lastname: "Please enter Last Name",
  please_enter_postcode: "Please enter Postcode",
  please_select_DOB: "Please select a Date of Birth",
  please_select_valid_date: "You need to be atleast 18 years old",
  please_enter_email: "Please enter Email",
  please_enter_valid_email: "Please enter valid Email",
  please_enter_name: "Please enter Name",
  enter_valid_username_password: "Please enter valid Username/Password",
  change_password: "Change Password",
  new_password: "New Password",
  successful: "Successful!",
  change: "Change",
  back: "Back",
  update: "Update",
  next: "Next",
  enter_address: "Enter your answer here",
  enter_postcode: "Enter your postcode here",
  start_making_money: "Start Making Money",
  welcome_screen_title: "Welcome!",
  welcome_screen_message:
    "We're excited to have you here. To help us tailor the best surveys for you, please take a moment to answer a few profile questions.",
  fill_out_quick_survey: "Fill out the quick survey!",
  earn_five_coins: "Earn 5 Coins",
  get_started: "Get Started",
  in_3_minutes: "In 3 minutes",
  explore_on_own: "Explore on my own.",
  skip: "Skip and",
  take_me_to_homepage: "Take me to the homepage",
  my_surveys: "My Surveys",
  answer_surveys: "Answer Surveys",
  my_wallet: "My Points",
  refer_users: "Refer Users",
  notifications: "Notifications",
  referals: "Referals",
  view_more: "View More",
  view: "View",
  new_surveys: "New Surveys",
  to_complete: "to complete",
  completed_surveys: "Completed Surveys",
  completed: "completed",
  survey_profile: "Survey Profile",
  survey_profile_message:
    "Choose a category and answer questions to unlock more qualifying surveys",
  get_two_coins: "Get <strong>2 coins</strong> for every 10 question*",
  best_tactics_for_more_coins: "Best tactics for more coins",
  profile_completion: "Profile completion",
  total_balance: "Total Balance",
  earnings: "Earnings",
  redeemed: "Redeemed",
  redeem: "Redeem",
  star_user_levels: "STAR USER LEVELS",
  bronze: "bronze",
  silver: "silver",
  gold: "gold",
  items_per_page: "Items per page",
  points_earned: "Points Earned",
  generic_error: "Service down. Please try again later.",
  profile_settings: "Profile Settings",
  logout: "Log out",
  earnings_log: "Earnings Log",
  earnings_by: "Earnings by",
  purchase_history: "Purchase History",
  redeem_points: "Redeem Points",
  description: "Description",
  terms: "Terms",
  add: "Add",
  cart: "Cart",
  close: "Close",
  reopen: "Reopen",
  no_stock: "No Stock",
  in_stock: "In Stock",
  remove: "Remove",
  points_redeemable: "Points Redeemable",
  total: "Total",
  purchase: "Purchase",
  successfully_purchased:
    "Thank you for your purchase. Your coupon has been sent to your registered email address.",
  go_to_surveys: "Go to Surveys",
  my_orders: "My Orders",
  deactivate_account: "Deactivate Account",
  filter: "Filter",
  edit: "Edit",
  support_form: "Support Form",
  support: "Support",
  category: "Category",
  select_category: "Select Category",
  enter_provider_description_here: "Enter provider description here",
  submit: "Submit",
  my_earnings: "My Earnings",
  first_name: "First Name",
  last_name: "Last Name",
  ethnicity: "Ethnicity",
  postcode: "Postcode",
  mobile_number: "Mobile Number",
  date_of_birth: "Date of Birth",
  emailid: "Email ID",
  address: "Address",
  gender: "Gender",
  marital_status: "Marital Status ",
  flexi_egift_card: "Flexi eGift Card",
  go_to_cart: "Go To Cart",
  confirm: "Confirm",
  deactivate_dialog_msg:
    "Are you sure you want to deactivate account? This action cannot be undone. ",
  support_dialog_msg: "Successfully support request sent!",
  support_description: "Enter issue description here",
  issues_list: "Issues List",
  view_retailers: "View Retailer Details",
  redemption_options: "Redemption Options ",
  support_request_sent: "Successfully support request sent!",
  show_full: "show full...",
  show_less: "show less...",
  invite_link: "Invite link",
  email_invites: "Email Invites",
  tab_survey: "Survey",
  tab_system: "System",
  tab_transactions: "Transactions",
  name: "Name",
  status: "Status",
  sent_date: "Sent Date",
  survey_link_fetch_failed:
    "Failed to fetch Survey link. Please try again later.",
  show_more: "Show More",
  error_message:
    "The service is currently down and our dev team are working on fixing it. Please try again later.",
  survey_number: "Survey Number",
  end_date: "End Date",
  completed_date: "Completed Date",
  points: "Points",
  survey_length: "Survey Length",
  survey_success_msg:
    "Congratulations!! You have successfully completed the survey. Survey points will be credited to your account.",
  survey_info_msg: "Thanks for completing the survey.",
  survey_failure_msg: "Unable to complete the Survey. Please try again later.",
  login_restricted: "You are not allowed to login from this country.",
};

export default en;
