import { Dialog, Divider, Stack } from "@mui/material";
import { BUTTON_SIZE } from "components/button/interface";
import HeaderWithFilter from "components/headerwithfilter";
import CustomSnackbar from "components/snackbar";
import { State } from "data/reducers/state";
import {
  deleteCartItemApi,
  purchaseCartApi,
  updateQuantityApi,
} from "data/slices/cart/api";
import { CartGenericResponse } from "data/slices/cart/interface";
import { CartCountActions } from "data/slices/cartcount";
import { CartListActions } from "data/slices/cartlist";
import { CartListStateProps } from "data/slices/cartlist/interface";
import { WalletDetailsActions } from "data/slices/wallet";
import { WalletDetailsStateProps } from "data/slices/wallet/interface";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DASHBOARD_DEFAULT_ROUTE, SNACKBAR_TYPE } from "utils/constants";
import { getCartList } from "utils/mappers/CartListMapper";
import { walletBalance } from "utils/Utils";
import CartPurchase from "widgets/dashboard/cart";

import SuccessTick from "../../../assets/icons/SuccessTick.svg";
import { CartContainerProps } from "../interface";
import {
  DialogContentWrapper,
  GoToSurveysButton,
  PurchaseSuccessText,
  Wrapper,
} from "../styled.components";

const CartContainer = (props: CartContainerProps) => {
  const { updateSidedrawerItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showPurchaseSuccessModel, setShowPurchaseSuccessModel] =
    useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });

  const { walletdetails } = useSelector<State, WalletDetailsStateProps>(
    (state) => state.walletdetails,
  );

  const { isFetching, cartlist, isError } = useSelector<
    State,
    CartListStateProps
  >((state) => state.cartlist);

  useEffect(() => {
    dispatch(CartListActions.request());
  }, [dispatch]);

  const cartItems = getCartList(cartlist);

  const deleteCartItemHandler = (id: number) => {
    deleteItem(id);
  };

  const purchaseCartItemsHandler = (walletBalance?, total?) => {
    purchaseItems();
  };

  const navigateToSurveys = () => {
    navigate(DASHBOARD_DEFAULT_ROUTE, { replace: true });
    updateSidedrawerItem(0);
  };

  const onNavigateBack = () => {
    navigate(-1);
  };

  const updateItemQuantityHandler = (id: number, quanity: number) => {
    updateQuantity(id, quanity);
  };

  const deleteItem = async (id: number) => {
    setIsLoading(true);
    const response: CartGenericResponse = await deleteCartItemApi({
      id: id,
    });

    setIsLoading(false);
    handleResponse(response);
  };

  const purchaseItems = async () => {
    setIsLoading(true);
    const response: CartGenericResponse = await purchaseCartApi();

    setIsLoading(false);
    handleResponse(response, true);
  };

  const updateQuantity = async (id: number, quanity: number) => {
    setIsLoading(true);

    if (quanity <= 0) {
      deleteItem(id);
    } else {
      const response: CartGenericResponse = await updateQuantityApi({
        id,
        quanity,
      });

      setIsLoading(false);
      handleResponse(response);
    }
  };

  //common response handler code for these api's
  const handleResponse = (
    response: CartGenericResponse,
    fromPurchase?: boolean,
  ) => {
    if (isUndefined(response) || isUndefined(response.message)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (!isUndefined(response.error)) {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    } else {
      //increment the global counter
      dispatch(CartCountActions.request());
      dispatch(CartListActions.request());
      //in purchase flow, instead of snackbar show dialog
      if (fromPurchase) {
        dispatch(WalletDetailsActions.request());
        setShowPurchaseSuccessModel(true);
      } else {
        onShowSnackbarHandler(response.message, SNACKBAR_TYPE.SUCCESS);
      }
    }
  };

  const handleCloseModel = () => setShowPurchaseSuccessModel(false);

  const onShowSnackbarHandler = (msg: string, type: number) => {
    setShowSnackbar(true);
    setSnackbarMsg({ msg, type });
  };

  return (
    <>
      <HeaderWithFilter
        title={t(keys.cart)}
        showFilter={false}
        showBackButton={false}
      />
      <Divider />
      <Wrapper>
        <CartPurchase
          cartlist={cartItems.items}
          isFetching={isFetching} //partial loader
          isError={isError}
          deleteCartItemHandler={deleteCartItemHandler}
          purchaseCartItemsHandler={purchaseCartItemsHandler}
          updateItemQuantityHandler={updateItemQuantityHandler}
          onNavigateBack={onNavigateBack}
          isLoading={isLoading} //full screen loader
          total={cartItems.total}
          walletbalance={walletBalance(walletdetails)}
        />
      </Wrapper>
      <CustomSnackbar
        msg={snackbarMsg.msg}
        type={snackbarMsg.type}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
      <Dialog
        maxWidth="sm"
        open={showPurchaseSuccessModel}
        onClose={handleCloseModel}
      >
        <DialogContentWrapper>
          <Stack direction="row" gap={1}>
            <img src={SuccessTick} alt="success" />
            <PurchaseSuccessText>
              {t(keys.successfully_purchased)}
            </PurchaseSuccessText>
          </Stack>
          <GoToSurveysButton
            onClickHandler={navigateToSurveys}
            size={BUTTON_SIZE.MEDIUM}
          >
            {t(keys.go_to_surveys)}
          </GoToSurveysButton>
        </DialogContentWrapper>
      </Dialog>
    </>
  );
};

export default CartContainer;
