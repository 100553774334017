import { resetPasswordApi } from "data/slices/resetpassword/api";
import { ResetPasswordResponse } from "data/slices/resetpassword/interface";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "utils/constants";
import PURE from "widgets/prelogin/changepassword";

import { LoginContainerProps } from "../interface";

const ChangePasswordContainer = (props: LoginContainerProps) => {
  const { onShowSnackbarHandler } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeClickedHandler = (password: string) => {
    changePassword(password);
  };

  const changePassword = async (password: string) => {
    setIsLoading(true);
    const forgotPasswordResponse: ResetPasswordResponse =
      await resetPasswordApi({
        token: params.token,
        userId: params.userid,
        newPassword: password,
        confirmPassword: password,
      });

    setIsLoading(false);
    if (isUndefined(forgotPasswordResponse)) {
      onShowSnackbarHandler(t(keys.generic_error));
    } else if (isUndefined(forgotPasswordResponse.error)) {
      setIsSuccess(true);
    } else {
      onShowSnackbarHandler(forgotPasswordResponse?.message);
    }
  };

  const onLoginClickedHandler = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <PURE
      onChangePasswordHandler={onChangeClickedHandler}
      isPasswordChanged={isSuccess}
      onLoginHandler={onLoginClickedHandler}
      isLoading={isLoading}
    />
  );
};

export default ChangePasswordContainer;
