import { MobileTableItemProps } from "commons/interface";
import { MobileCardTitle, MobileItemCard } from "commons/styled.components";
import CustomLink from "components/link";
import keys from "locale/keys";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TABLE_TYPE_ORDERS,
  TABLE_TYPE_REFERALS,
  TABLE_TYPE_RETAILERS,
  TABLE_TYPE_WALLET_EARNINGS,
} from "utils/constants";
import { getStringWithDefaultValue, termsTextToShow } from "utils/Utils";

import calendar from "../../assets/icons/calendar.svg";
import RetailerPlaceholder from "../../assets/icons/CouponPlaceholder.svg";
import {
  MobileItemDescription,
  MobileItemValue,
  RetailerImageWrapper,
  RetailerWrapper,
  StackItem,
  TableItemText,
} from "./styled.components";
import { getPointsItem, getStatus } from "./TableItem";

const MobileTableItem = (props: MobileTableItemProps) => {
  const {
    tableType,
    transactionid,
    couponoff,
    value,
    surveyName,
    surveyNumber,
    completedDate,
    title,
    email,
    points,
    headerItems,
    dateofpurchase,
    description,
    status,
    iconurl,
  } = props;
  const { t } = useTranslation();
  const [showFullText, setShowFullText] = useState(false);

  const getItemTitle = (title: String) => {
    return `${title} : `;
  };

  const getItemWithIcon = (icon: string, date: string) => {
    return (
      <StackItem direction="row" alignItems="center" gap={0}>
        <img src={icon} alt="calendar" width="16px" height="16px" />
        <MobileItemValue>{date}</MobileItemValue>
      </StackItem>
    );
  };

  const getOrdersItems = () => {
    return (
      <MobileItemCard>
        <MobileCardTitle>{couponoff}</MobileCardTitle>
        <table>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[0])}
            </MobileItemDescription>
            <MobileItemValue>{transactionid}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[2])}
            </MobileItemDescription>
            <MobileItemValue>{value}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[3])}
            </MobileItemDescription>
            <MobileItemValue>{points}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[4])}
            </MobileItemDescription>
            <MobileItemValue>
              {getItemWithIcon(calendar, dateofpurchase)}
            </MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[5])}
            </MobileItemDescription>
            <MobileItemValue>{getStatus(status)}</MobileItemValue>
          </tr>
        </table>
      </MobileItemCard>
    );
  };

  const getWalletEarningsItem = () => {
    return (
      <MobileItemCard>
        <MobileCardTitle>{surveyName}</MobileCardTitle>
        <table>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[1])}
            </MobileItemDescription>
            <MobileItemValue>{surveyNumber}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[2])}
            </MobileItemDescription>
            <MobileItemValue>
              {getItemWithIcon(calendar, completedDate)}
            </MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[3])}
            </MobileItemDescription>
            <MobileItemValue>{getPointsItem(points)}</MobileItemValue>
          </tr>
        </table>
      </MobileItemCard>
    );
  };

  const getReferalsItem = () => {
    return (
      <MobileItemCard>
        <MobileCardTitle>{email}</MobileCardTitle>
        <table>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[0])}
            </MobileItemDescription>
            <MobileItemValue>{title}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[1])}
            </MobileItemDescription>
            <MobileItemValue>{status}</MobileItemValue>
          </tr>
          <tr>
            <MobileItemDescription>
              {getItemTitle(headerItems[3])}
            </MobileItemDescription>
            <MobileItemValue>{completedDate}</MobileItemValue>
          </tr>
        </table>
      </MobileItemCard>
    );
  };

  const getRetailsItem = () => {
    return (
      <MobileItemCard>
        <RetailerWrapper>
          <RetailerImageWrapper>
            <img
              src={iconurl}
              alt="retailer not found"
              width="100%"
              height="160px"
              loading="lazy"
              onError={(e) => {
                e.currentTarget.src = RetailerPlaceholder;
              }}
            />
          </RetailerImageWrapper>
          <span>
            <TableItemText>
              {getStringWithDefaultValue(
                termsTextToShow(description, showFullText),
              )}
            </TableItemText>
            <CustomLink onClickHandler={() => setShowFullText((val) => !val)}>
              {showFullText ? t(keys.show_less) : t(keys.show_full)}
            </CustomLink>
          </span>
        </RetailerWrapper>
      </MobileItemCard>
    );
  };

  if (tableType === TABLE_TYPE_ORDERS) {
    return getOrdersItems();
  } else if (tableType === TABLE_TYPE_WALLET_EARNINGS) {
    return getWalletEarningsItem();
  } else if (tableType === TABLE_TYPE_REFERALS) {
    return getReferalsItem();
  } else if (tableType === TABLE_TYPE_RETAILERS) {
    return getRetailsItem();
  } else {
    <></>;
  }
};

export default MobileTableItem;
