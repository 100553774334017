import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getAxios } from "utils/networkutils";

import { LoginRequestProps } from "./interface";

export const loginUserApi = async (payload: LoginRequestProps) => {
  try {
    const { data } = await axios.post(getBaseURL() + ENDPOINTS.LOGIN, payload);

    return data;
  } catch (e) {
    return {
      message: e.response?.data?.message,
      error: { message: e.response?.data?.message },
    }; //manually added error for error snackbar validation
  }
};

export const getUserCountry = async () => {
  try {
    const { data } = await getAxios().get(ENDPOINTS.GEO_INFO);

    return data?.country;
  } catch (e) {
    return "UK"; //for now allow user to login even if this api fails. See if we can get from sdk
  }
};
