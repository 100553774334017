import { TablePagination } from "@mui/material";
import { TablePaginationActions } from "commons";
import { PaginationWrapper } from "commons/styled.components";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import GenericTable from "components/table/GenericTable";
import keys from "locale/keys";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGE,
  DEFAULT_ROW_OPTIONS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE,
} from "utils/constants";

import { EarningsLogProps } from "./interface";

const EarningsLog = (props: EarningsLogProps) => {
  const {
    earningsToDisplay,
    isLoading,
    isError,
    fetchCoinsHistory,
    recordsCount,
  } = props;
  const { t } = useTranslation();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    fetchCoinsHistory(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchCoinsHistory(0, newRowsPerPage);
  };

  const showEarnings = () => {
    const isEmpty = earningsToDisplay?.tableItems?.length === 0;
    if (isLoading || isEmpty || isError) {
      return (
        <LoadingEmptyError
          isLoading={isLoading}
          isEmpty={isEmpty}
          isError={isError}
          largesize={true}
        />
      );
    }

    return (
      <>
        <GenericTable {...earningsToDisplay} />
        <PaginationWrapper data-testid="pagination-wrapper-earnings">
          <TablePagination
            rowsPerPageOptions={DEFAULT_ROW_OPTIONS_PER_PAGE}
            colSpan={3}
            count={recordsCount}
            labelRowsPerPage={t(keys.items_per_page)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{
              borderBottom: "none",
            }}
          />
        </PaginationWrapper>
      </>
    );
  };

  return showEarnings();
};

export default EarningsLog;
