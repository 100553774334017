import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import IconWithCount from "components/iconwithcount";
import Profile from "components/profile";
import { State } from "data/reducers/state";
import { CartCountActions } from "data/slices/cartcount";
import { CartCountStateProps } from "data/slices/cartcount/interface";
import { UserDetailsActions } from "data/slices/userdetails";
import { UserDetailsStateProps } from "data/slices/userdetails/interface";
import { WalletDetailsActions } from "data/slices/wallet";
import { WalletDetailsStateProps } from "data/slices/wallet/interface";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DASHBOARD_ROUTES, ROUTES } from "utils/constants";
import { getUserName, logout, walletBalance } from "utils/Utils";

import surveyLogo from "../../assets/icons/ApplogoBlack.svg";
import { TopBarProps } from "./interface";
import {
  IconWrapper,
  ItemImage,
  ListContent,
  LogoWrapper,
  MenuIconWrapper,
  MenuWrapper,
  UserRelatedDetails,
  Wallet,
  Wrapper,
} from "./styled.components";
import { MENU_ITEMS } from "./utils";

const TopBar = (props: TopBarProps) => {
  const { onMenuItemSelected } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState(0);

  const { cartcount } = useSelector<State, CartCountStateProps>(
    (state) => state.cartcount,
  );

  const { walletdetails } = useSelector<State, WalletDetailsStateProps>(
    (state) => state.walletdetails,
  );

  const { details } = useSelector<State, UserDetailsStateProps>(
    (state) => state.userdetails,
  );

  const onShowCartHandler = () => {
    navigate(DASHBOARD_ROUTES.CART);
  };

  const [open, setOpen] = React.useState(false);

  const toogleOpen = () => {
    setOpen((open) => !open);
  };

  const modalRef = useRef(null);
  const handleClickOutside = (event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSidebarItemSelected = (selectedRoute: string) => {
    navigate(selectedRoute, {
      replace: true,
    });
    if (selectedRoute === ROUTES.LOGIN) {
      logout();
      dispatch(UserDetailsActions.reset());
      dispatch(WalletDetailsActions.reset());
      dispatch(CartCountActions.reset());
    }
    setOpen((open) => !open);
  };
  const onItemSelected = (index: number) => {
    setSelectedItem(index);
    onSidebarItemSelected(MENU_ITEMS[index].route);
  };

  const getListItem = (title: string, index: number) => {
    return (
      <ListItemText
        primary={title}
        sx={{
          opacity: open ? 1 : 0,
          paddingLeft: "12px",
          "& .MuiTypography-root": {
            fontSize: "0.88rem",
            fontWeight: "500",
            color: selectedItem === index ? "#FFF" : "#000000",
            width: "max-content",
          },
        }}
      />
    );
  };

  return (
    <Wrapper>
      <div>
        <LogoWrapper src={surveyLogo} alt="logo" />
      </div>
      <UserRelatedDetails>
        <IconWrapper>
          <Wallet balance={`${walletBalance(walletdetails)}`} />
        </IconWrapper>
        {cartcount > 0 && (
          <IconWithCount
            count={cartcount}
            onShowCartHandler={onShowCartHandler}
          />
        )}
        <Profile
          name={getUserName(details?.firstName, details?.lastName)}
          onMenuItemSelected={onMenuItemSelected}
          showMenuOptions={true}
          profileUrl={details?.profilePic}
        />

        <MenuIconWrapper>
          <MenuWrapper
            onClick={toogleOpen}
            sx={{ fontSize: "2rem", color: "black" }}
          />
          {open && (
            <ListContent>
              <List ref={modalRef}>
                {MENU_ITEMS.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: "block", padding: "0px 8px", width: "100%" }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          selectedItem === index ? "#158A73" : "",
                        borderRadius: "4px",
                      }}
                    >
                      <ListItemButton
                        selected={selectedItem === index}
                        onClick={() => onItemSelected(index)}
                        disabled={!item.enabled}
                        sx={{
                          minHeight: 36,
                          justifyContent: open ? "initial" : "center",
                          width: "100%",
                        }}
                      >
                        <ItemImage
                          src={item.icon}
                          alt="side drawer item icon"
                          className={selectedItem === index ? "ItemColor" : ""}
                        />
                        {getListItem(item.title, index)}
                      </ListItemButton>
                    </div>
                  </ListItem>
                ))}
              </List>
            </ListContent>
          )}
        </MenuIconWrapper>
      </UserRelatedDetails>
    </Wrapper>
  );
};

export default TopBar;
