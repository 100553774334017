import { Grid } from "@mui/material";
import { InputFieldInputStyle } from "commons/styled.components";
import keys from "locale/keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { validateEmail, validateName } from "widgets/prelogin/utils";

import Send from "../../../assets/icons/Send.svg";
import { EmailInviteProps, InviteesProps } from "./interface";
import {
  InviteDetailsTextField,
  InviteRowWrapper,
  SectionTitle,
  SectionWrapper,
  SendButton,
  SendWrapper,
} from "./styled.components";

const EmailInvites = (props: EmailInviteProps) => {
  const { sendUserInvite, resetInput } = props;
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [validate, setValidate] = useState("");
  const [invitees, setInvitees] = useState<InviteesProps[]>([
    {
      id: uuidv4(),
      name: "",
      email: "",
      isValidName: true,
      isValidEmail: true,
    },
  ]);

  //for now setting only one record. once multiple send option is added, remove this effect.
  useEffect(() => {
    setInvitees((prev) => [
      {
        id: uuidv4(),
        name: "",
        email: "",
        isValidName: true,
        isValidEmail: true,
      },
    ]);
  }, [resetInput]);

  const onUpdateName = (value: string, index: number) => {
    const invitee = invitees[index];
    invitee.name = value;
  };

  const onUpdateEmail = (value: string, index: number) => {
    const invitee = invitees[index];
    invitee.email = value;
  };

  const emailValidation = (index: number) => {
    const invitee = invitees[index];
    const model = validateEmail(invitee.email);
    invitee.isValidEmail = model.isValid;
    invitee.emailMessage = model.message;
    setValidate(uuidv4());
  };

  const nameValidation = (index: number) => {
    const invitee = invitees[index];
    const model = validateName(invitee.name, keys.please_enter_name);
    invitee.isValidName = model.isValid;
    invitee.nameMessage = model.message;
    setValidate(uuidv4());
  };

  /* const onAddNewInvitee = () => {
    setInvitees((prev) => [
      ...prev,
      {
        id: uuidv4(),
        name: "",
        email: "",
        isValidName: true,
        isValidEmail: true,
      },
    ]);
  };

  const onRemoveInvitee = (index: number) => {
    setInvitees((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  }; */

  const onSendUserInvite = () => {
    //for now only 1 user is invited
    //TODO: instead a single method to validate both name and email
    emailValidation(0);
    nameValidation(0);
    if (invitees[0].isValidName && invitees[0].isValidEmail) {
      sendUserInvite(invitees[0].name, invitees[0].email);
    }
  };

  const addInviteRow = (item: InviteesProps, index: number) => {
    //const isLast = isEqual(index, invitees.length - 1);
    return (
      <InviteRowWrapper key={item.id}>
        <InviteDetailsTextField
          placeholder={t(keys.enter_name)}
          type="text"
          autoComplete="off"
          onInput={(e: any) => {
            onUpdateName(e.target.value, index);
          }}
          error={!item?.isValidName}
          onBlur={() => nameValidation(index)}
          helperText={item?.nameMessage}
          inputProps={InputFieldInputStyle(item?.isValidName)}
        ></InviteDetailsTextField>
        <InviteDetailsTextField
          placeholder={t(keys.enter_email)}
          type="email"
          autoComplete="off"
          onInput={(e: any) => {
            onUpdateEmail(e.target.value, index);
          }}
          error={!item?.isValidEmail}
          onBlur={() => emailValidation(index)}
          helperText={item?.emailMessage}
          inputProps={InputFieldInputStyle(item?.isValidEmail)}
        ></InviteDetailsTextField>
        {/* {isLast && (
          <AddRemoveIconButton onClick={() => onAddNewInvitee()}>
            <Add fontSize="small" />
          </AddRemoveIconButton>
        )}
        {!isLast && (
          <AddRemoveIconButton onClick={() => onRemoveInvitee(index)}>
            <Remove fontSize="small" />
          </AddRemoveIconButton>
        )} */}
      </InviteRowWrapper>
    );
  };

  return (
    <SectionWrapper style={{ marginTop: "0.5rem" }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <SectionTitle>{t(keys.email_invites)}</SectionTitle>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          {invitees.map((item, index) => {
            return addInviteRow(item, index);
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <SendWrapper>
            <SendButton
              variant="outlined"
              startIcon={<img src={Send} alt="send-icon" />}
              style={{ alignSelf: "end" }}
              onClick={() => onSendUserInvite()}
            >
              {t(keys.send)}
            </SendButton>
          </SendWrapper>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default EmailInvites;
