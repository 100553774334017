import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { COLORS } from "utils/colors";

import { getFontSize, getPadding } from "./utils";

export const CustomButton = styled(Button)<{
  margintop: string;
  bgcolor: string;
  textcolor: string;
  buttonsize: number;
}>`
  border-radius: 4px;
  border-color: ${COLORS.BRAND_DEFAULT_COLOR};
  background-color: #000000;
  padding: ${(props) => getPadding(props.buttonsize)};
  margin: 0px;
  margin-top: ${(props) => props.margintop};
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
  text-transform: none;
  font-size: ${(props) => getFontSize(props.buttonsize)};

  :hover {
    background-color: ${(props) => props.bgcolor};
    border-color: black;
  }
`;
