import { CircularProgress } from "@mui/material";
import i18n from "i18n";
import keys from "locale/keys";

import EmptyState from "../../assets/icons/EmptyState.svg";
import ErrorState from "../../assets/icons/ErrorState.svg";
import { LoadingEmptyErrorProps } from "./interface";
import {
  EmptyErrorImage,
  ErrorMessageText,
  LoaderWrapper,
} from "./styled.components";

const LoadingEmptyError = (props: LoadingEmptyErrorProps) => {
  const { isLoading, isEmpty, isError, largesize = false } = props;

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  if (isError) {
    return (
      <LoaderWrapper>
        <EmptyErrorImage
          largesize={largesize}
          src={ErrorState}
          alt="error-state"
        />
        <ErrorMessageText>{i18n.t(keys.error_message)}</ErrorMessageText>
      </LoaderWrapper>
    );
  }

  if (isEmpty) {
    return (
      <LoaderWrapper>
        <EmptyErrorImage
          largesize={largesize}
          src={EmptyState}
          alt="empty-state"
        />
      </LoaderWrapper>
    );
  }
};

export default LoadingEmptyError;
