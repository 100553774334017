import styled from "@emotion/styled";
import { Alert, css, FormControl, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";
import TextField from "components/textfield";

import PreloginBG from "../../assets/bg/PreloginBG.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  background: #4d9486;

  ${mobile(css`
    flex-direction: column;
    background-image: url(${PreloginBG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    box-sizing: border-box;
    overflow: scroll;
  `)};
`;

export const LeftPanelWrapper = styled.div`
  width: 30%;
  padding: 2rem;
  background-image: url(${PreloginBG});
  background-repeat: no-repeat;
  background-size: cover;

  ${mobile(css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: none;
    padding: 1rem;
    box-sizing: border-box;
  `)};
`;

export const WelcomeMessageTitle = styled(Typography)`
  color: white;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-top: 30px;

  ${mobile(css`
    font-size: 30px;
  `)};
`;

export const AppLogImage = styled.img`
  width: 180px;

  ${mobile(css`
    margin-left: auto;
    margin-right: auto;
  `)};
`;

export const WelcomeMessageDescription = styled(Typography)`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 13px;

  ${mobile(css`
    font-size: 14px;
  `)};
`;

export const RightPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  background: white;
  border-radius: 30px 0px 0px 30px;

  ${mobile(css`
    width: 100%;
    padding: 1.5rem 1rem;
    margin-top: 1rem;
    border-radius: 4px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
  `)};
`;

export const BaseForm = styled.form`
  min-width: 45%;

  ${mobile(css`
    min-width: 90%;
  `)};
`;

export const LoginWrapper = styled(FormControl)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  z-index: 30;
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const LabelWithLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16dp;
  gap: 4px;
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`;

export const StyledAlert = styled(Alert)`
  border-radius: 4px;
  border: 1px solid #f1aeb5;
  background: #f8d7da;
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  color: #b02a37;
  font-size: 1rem;
  font-weight: 400;
`;

export const SignupNameWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const LoginTextField = styled(TextField)``;
