import { useMediaQuery, useTheme } from "@mui/material";
import Button from "components/button";
import { BUTTON_SIZE } from "components/button/interface";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";
import { appendPoints } from "utils/Utils";

import CartItem from "./CartItem";
import { CartListProps } from "./interface";
import {
  CartContentWrapper,
  CartItemsWrapper,
  CartWrapper,
  DummyElement,
  OptionsWrapper,
  SummaryBarTitle,
  SummaryBarValue,
  SummaryBarWrapper,
} from "./styled.components";

const CartPurchase = (props: CartListProps) => {
  const { t } = useTranslation();
  const {
    cartlist,
    isError,
    isFetching,
    deleteCartItemHandler,
    purchaseCartItemsHandler,
    updateItemQuantityHandler,
    onNavigateBack,
    total = 0,
    walletbalance = 0,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isEmpty = cartlist && cartlist.length === 0;
  if (isError || isFetching || isEmpty) {
    return (
      <LoadingEmptyError
        isEmpty={isEmpty}
        isError={isError}
        isLoading={isFetching}
        largesize={true}
      />
    );
  }

  const summaryBar = (title: string, value: string, bgcolor: string) => {
    return (
      <SummaryBarWrapper bgcolor={bgcolor}>
        <SummaryBarTitle>{title}</SummaryBarTitle>
        <SummaryBarValue>{value}</SummaryBarValue>
        {!isMobile && <DummyElement></DummyElement>}
      </SummaryBarWrapper>
    );
  };

  return (
    <>
      <CartContentWrapper>
        <CartWrapper>
          {summaryBar(
            t(keys.points_redeemable),
            appendPoints(walletbalance),
            "#F2F2F2",
          )}
          <CartItemsWrapper data-testid="cart-items-wrapper">
            {cartlist.map((cart, index) => (
              <CartItem
                {...cart}
                key={index}
                isMobile={isMobile}
                deleteCartItemHandler={deleteCartItemHandler}
                updateItemQuantityHandler={updateItemQuantityHandler}
              />
            ))}
          </CartItemsWrapper>
          {summaryBar(t(keys.total), appendPoints(total), "#EEF5F0")}
        </CartWrapper>
      </CartContentWrapper>
      <OptionsWrapper>
        <Button
          variant="outlined"
          size={BUTTON_SIZE.MEDIUM}
          onClickHandler={onNavigateBack}
        >
          {t(keys.back)}
        </Button>
        <Button
          variant="contained"
          size={BUTTON_SIZE.MEDIUM}
          onClickHandler={() => purchaseCartItemsHandler(walletbalance, total)}
        >
          {t(keys.redeem)}
        </Button>
      </OptionsWrapper>
    </>
  );
};

export default withLoader(CartPurchase);
