import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";

import rootSaga from "./reducers/saga";
import cartcountReducer from "./slices/cartcount/index";
import cartlistReducer from "./slices/cartlist/index";
import coinshistoryReducer from "./slices/coinshistory/index";
import flexicardsReducer from "./slices/flexicarddetails/index";
import giftcardsReducer from "./slices/giftcards/index";
import SupportTicketsReducer from "./slices/support/index";
import surveyListReducer from "./slices/surveylist/index";
import userdetailsReducer from "./slices/userdetails/index";
import walletdetailsReducer from "./slices/wallet/index";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export default configureStore({
  reducer: {
    surveylist: surveyListReducer,
    userdetails: userdetailsReducer,
    walletdetails: walletdetailsReducer,
    giftcards: giftcardsReducer,
    cartcount: cartcountReducer,
    cartlist: cartlistReducer,
    coinshistory: coinshistoryReducer,
    supporttickets: SupportTicketsReducer,
    flexicards: flexicardsReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

sagaMiddleware.run(rootSaga);
