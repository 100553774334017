import { forgotPasswordApi } from "data/slices/forgotpassword/api";
import { ForgotPasswordResponse } from "data/slices/forgotpassword/interface";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ROUTES } from "utils/constants";
import PURE from "widgets/prelogin/forgotpassword";

import { LoginContainerProps } from "../interface";

export const ForgotPasswordContainer = (props: LoginContainerProps) => {
  const { onShowSnackbarHandler } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sendRecoveryMail, setSendRecoveryMail] = useState(false);

  const onLoginClickedHandler = () => {
    navigate(ROUTES.LOGIN);
  };

  const onSendRecoveryMailHandler = (email: string) => {
    sendRecoveryLink(email);
  };

  const sendRecoveryLink = async (email: string) => {
    setIsLoading(true);
    const forgotPasswordResponse: ForgotPasswordResponse =
      await forgotPasswordApi({
        email,
      });

    setIsLoading(false);
    if (isUndefined(forgotPasswordResponse)) {
      onShowSnackbarHandler(t(keys.generic_error));
    } else if (isUndefined(forgotPasswordResponse.error)) {
      setSendRecoveryMail(true);
    } else {
      onShowSnackbarHandler(forgotPasswordResponse?.message);
    }
  };

  return (
    <PURE
      isRecoveryMailSent={sendRecoveryMail}
      onLoginClickedHandler={onLoginClickedHandler}
      onSendRecoveryMailHandler={onSendRecoveryMailHandler}
      isLoading={isLoading}
    />
  );
};

export default ForgotPasswordContainer;
