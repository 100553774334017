import * as React from "react";
import { useTranslation } from "react-i18next";

import ApplogoWhite from "../../assets/icons/ApplogoWhite.svg";
import keys from "../../locale/keys";
import {
  AppLogImage,
  LeftPanelWrapper,
  WelcomeMessageDescription,
  WelcomeMessageTitle,
} from "./styled.components";

const LeftPanel = () => {
  const { t } = useTranslation();
  return (
    <LeftPanelWrapper>
      <AppLogImage src={ApplogoWhite} alt="app logo" />
      <WelcomeMessageTitle>
        {t(keys.prelogin_welcome_message)}
      </WelcomeMessageTitle>
      <WelcomeMessageDescription>
        {t(keys.prelogin_welcome_description)}
      </WelcomeMessageDescription>
    </LeftPanelWrapper>
  );
};

export default LeftPanel;
