import { Stack } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import TooltipIcon from "../../../assets/icons/TooltipIcon.svg";
import { ChartProps } from "./interface";
import {
  TooltipPointsWrapper,
  TooltipText,
  TooltipWrapper,
} from "./styled.components";

const BARCOLOR = "#D0E8E3";

const BalanceChart = (props: ChartProps) => {
  const { chartData } = props;

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload) {
      return (
        <TooltipWrapper>
          <Stack direction="row" gap="0.5rem" alignItems="center">
            <img
              src={TooltipIcon}
              alt="tooltip-icon"
              width="24px"
              height="24px"
            />
            <TooltipText>{payload[0]?.payload?.tooltipDate}</TooltipText>
          </Stack>
          <TooltipPointsWrapper>
            <TooltipText>
              Earning <strong>{payload[0].value.toFixed(2)} pts</strong>
            </TooltipText>
          </TooltipPointsWrapper>
        </TooltipWrapper>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={200}
        height={200}
        data={chartData}
        margin={{ top: 24, left: 0, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          tick={{ fontSize: "0.8rem", fill: "black" }}
          tickLine={false}
        />
        <YAxis
          tickLine={false}
          tick={{ fontSize: "0.8rem", fill: "black" }}
          width={35}
        />
        <Tooltip cursor={false} content={<CustomTooltip />} />
        <Bar
          dataKey="coins"
          fill={BARCOLOR}
          barSize={32}
          shape={<Rectangle radius={4} />}
          activeBar={
            <Rectangle
              fill={BARCOLOR}
              stroke="blue"
              strokeWidth={2}
              radius={4}
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BalanceChart;
