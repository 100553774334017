import ProfileSettingsContainer from "containers/profile";
import SupportContainer from "containers/support";
import { Route, Routes, useNavigate } from "react-router";
import { DASHBOARD_ROUTES } from "utils/constants";

import CartContainer from "./cart";
import { DashboardContentProps } from "./interface";
import MyOrdersContainer from "./myorders";
import MyWalletContainer from "./mywallet/MyWalletContainer";
import NotificationsContainer from "./notifications";
import RedeemPointsContainer from "./redeem";
import ReferalContainer from "./referals";
import SurveysContainer from "./surveys/SurveysContainer";

const DashboardContent = (props: DashboardContentProps) => {
  const { updateSidedrawerItem } = props;
  const navigate = useNavigate();

  //routing from within the container is causing concatination issue, so navigating from here
  const navigateToRoute = (route: string) => {
    navigate(route, {
      replace: true,
    });
  };

  return (
    <Routes>
      <Route path={DASHBOARD_ROUTES.TASKS} element={<SurveysContainer />} />
      <Route
        path={DASHBOARD_ROUTES.MY_WALLET}
        element={<MyWalletContainer />}
      />
      <Route
        path={DASHBOARD_ROUTES.MY_ORDERS}
        element={<MyOrdersContainer />}
      />
      <Route
        path={DASHBOARD_ROUTES.REDEEM}
        element={<RedeemPointsContainer navigateToRoute={navigateToRoute} />}
      />
      <Route
        path={DASHBOARD_ROUTES.CART}
        element={<CartContainer updateSidedrawerItem={updateSidedrawerItem} />}
      />
      <Route
        path={DASHBOARD_ROUTES.PROFILE_SETTINGS}
        element={<ProfileSettingsContainer />}
      />
      <Route
        path={DASHBOARD_ROUTES.REFER_USERS}
        element={<ReferalContainer />}
      />
      <Route
        path={DASHBOARD_ROUTES.NOTIFICATIONS}
        element={<NotificationsContainer />}
      />
      <Route path={DASHBOARD_ROUTES.SUPPORT} element={<SupportContainer />} />
    </Routes>
  );
};

export default DashboardContent;
