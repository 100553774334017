import { GenericTableProps, TableItemProps } from "commons/interface";
import {
  CoinsHistoryFilterResponse,
  CoinsHistoryItem,
} from "data/slices/coinshistory/interface";
import { AchievementTierDetails } from "data/slices/wallet/interface";
import i18n from "i18n";
import keys from "locale/keys";
import { isEqual, isUndefined } from "lodash";
import {
  DEFAULT_PLACEAHOLDER,
  EARNING_TYPE_PROFILE,
  EARNINGS_DATE_FORMAT,
  POINTS_FILTER_BY,
  TABLE_TYPE_WALLET_EARNINGS,
} from "utils/constants";
import {
  appendPoints,
  decrementOneDay,
  formatDate,
  getDateInFormat,
  getStringWithDefaultValue,
} from "utils/Utils";
import { ChartDataField } from "widgets/dashboard/wallet/interface";

export const getWalletEarningLogs = (
  coinshistory: CoinsHistoryItem[],
): GenericTableProps => {
  const earnings: TableItemProps[] = [];

  if (coinshistory) {
    coinshistory.forEach((coin) => {
      const isEarningTypeProfile = isEqual(coin.type, EARNING_TYPE_PROFILE);
      earnings.push({
        surveyName: isEarningTypeProfile
          ? i18n.t(keys.profile_completion)
          : getStringWithDefaultValue(coin.masterSurvay?.surveyName),
        surveyNumber: isEarningTypeProfile
          ? DEFAULT_PLACEAHOLDER
          : `${coin.surveyId}`,
        points: appendPoints(coin.coins),
        completedDate: formatDate(coin.createdAt, EARNINGS_DATE_FORMAT),
      });
    });
  }

  return {
    tableType: TABLE_TYPE_WALLET_EARNINGS,
    headerItems: getHeaders(),
    tableItems: earnings,
  };
};

const getHeaders = () => {
  return [
    i18n.t(keys.earnings_by),
    i18n.t(keys.survey_number),
    i18n.t(keys.completed_date),
    i18n.t(keys.points_earned),
  ];
};

export const getChartData = (
  filterResponse: CoinsHistoryFilterResponse,
  filterType: string,
): ChartDataField[] => {
  const data: ChartDataField[] = [];
  if (!filterResponse || filterResponse?.response?.rows?.length === 0) {
    return data;
  }

  if (
    isEqual(filterType, POINTS_FILTER_BY.WEEK) ||
    isEqual(filterType, POINTS_FILTER_BY.MONTH)
  ) {
    getFilterDataByWeekAndMonth(filterResponse, filterType, data);
  } else {
    getFilterDataByMonths(filterResponse, filterType, data);
  }

  //show the date in reverse
  if (data.length > 0) {
    return data.reverse();
  }

  return data;
};

//TODO: optimize the iterations
const getFilterDataByWeekAndMonth = (
  filterResponse: CoinsHistoryFilterResponse,
  filterType: string,
  data: ChartDataField[],
) => {
  const days = isEqual(filterType, POINTS_FILTER_BY.WEEK) ? 7 : 30;
  var currentDate = new Date();
  for (var i = 0; i < days; i++) {
    const currentDateInFormat = getDateInFormat(currentDate);
    let coins = 0;
    filterResponse.response.rows.forEach((item) => {
      if (isEqual(item.createdAt, currentDateInFormat.formatted)) {
        coins = item.coins;
        return;
      }
    });

    //set data
    data.push({
      label: currentDateInFormat.day,
      coins: coins,
      tooltipDate: currentDateInFormat.tooltip,
    });

    //now check 1 day before
    currentDate = decrementOneDay(currentDate);
  }
};

//for months show the aggregated values of current month till given months
//TODO: optimize the iterations
const getFilterDataByMonths = (
  filterResponse: CoinsHistoryFilterResponse,
  filterType: string,
  data: ChartDataField[],
) => {
  const daysToShow = isEqual(filterType, POINTS_FILTER_BY.QUARTER) ? 90 : 90;
  var currentDate = new Date();
  var currentDateInFormat = getDateInFormat(currentDate);

  let coins = 0;
  for (var i = 0; i < daysToShow; i++) {
    // eslint-disable-next-line no-loop-func
    filterResponse.response.rows.forEach((item) => {
      if (isEqual(item.createdAt, currentDateInFormat.formatted)) {
        coins = coins + item.coins;
      }
    });

    //reached start of current month
    if (
      isEqual(currentDateInFormat.day, "01") ||
      isEqual(currentDateInFormat.day, "1")
    ) {
      data.push({
        label: currentDateInFormat.month,
        coins: coins,
        tooltipDate: currentDateInFormat.month,
      });
      coins = 0;
    }

    currentDate = decrementOneDay(currentDate);
    currentDateInFormat = getDateInFormat(currentDate);
  }

  //push for the last month, as 01 might not reach
  data.push({
    label: currentDateInFormat.month,
    coins: coins,
    tooltipDate: currentDateInFormat.month,
  });
};

export const getAchievementTierDetails = (
  response: AchievementTierDetails[],
) => {
  if (isUndefined(response)) {
    return [];
  }
  return response;
};
