import i18n from "i18n";
import keys from "locale/keys";
import { isEmpty, isEqual } from "lodash";
import { EMAIL_REGEX } from "utils/constants";

import {
  ChangePasswordValidationModel,
  SignupValidationModel,
  ValidationModel,
} from "./interface";

export const validateLogin = (
  email: string,
  password: string,
): ValidationModel => {
  const validationModel: ValidationModel = {
    isValidUsername: true,
    isValidPassword: true,
    userNameMessage: "",
    passwordMessage: "",
  };

  //validate email
  const emailValidation = validateEmail(email);
  validationModel.isValidUsername = emailValidation.isValid;
  validationModel.userNameMessage = emailValidation.message;

  //validate password
  const passwordValidation = validatePassword(password);
  validationModel.isValidPassword = passwordValidation.isValid;
  validationModel.passwordMessage = passwordValidation.message;

  return validationModel;
};

export const validateEmail = (email: string) => {
  var isValid = true;
  var message = "";

  if (isEmpty(email)) {
    isValid = false;
    message = i18n.t(keys.please_enter_email);
  } else if (!email.match(EMAIL_REGEX)) {
    isValid = false;
    message = i18n.t(keys.please_enter_valid_email);
  }

  return {
    isValid,
    message,
  };
};

export const validateName = (name: string, messageKey: string) => {
  var isValid = true;
  var message = "";

  if (isEmpty(name)) {
    isValid = false;
    message = i18n.t(messageKey);
  } else {
    const NAME_REGEX = /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/;
    /** Validates a name field (first or last name) */
    const isValidName = NAME_REGEX.test(name);

    if (!isValidName) {
      isValid = false;
      message = i18n.t(keys.please_enter_validname);
    }
  }

  return {
    isValid,
    message,
  };
};

export const validatePassword = (password: string) => {
  var isValid = true;
  var message = "";

  if (isEmpty(password)) {
    isValid = false;
    message = i18n.t(keys.please_enter_password);
  }

  return {
    isValid,
    message,
  };
};

export const validatePasswordForStrength = (
  password: string,
  passwordStrengthValidated: boolean,
) => {
  var isValid = true;
  var message = "";

  if (isEmpty(password)) {
    isValid = false;
    message = i18n.t(keys.please_enter_password);
  } else if (!passwordStrengthValidated) {
    isValid = false;
    message = i18n.t(keys.passwords_not_matching_validations);
  }

  return {
    isValid,
    message,
  };
};

export const validateSimpleInput = (input: string, validationKey: string) => {
  var isValid = true;
  var message = "";

  if (isEmpty(input)) {
    isValid = false;
    message = i18n.t(validationKey);
  }

  return {
    isValid,
    message,
  };
};

export const validateForgotPassword = (email: string): ValidationModel => {
  const validationModel: ValidationModel = {
    isValidUsername: true,
    userNameMessage: "",
  };

  //validate email
  const emailValidation = validateEmail(email);
  validationModel.isValidUsername = emailValidation.isValid;
  validationModel.userNameMessage = emailValidation.message;

  return validationModel;
};

export const validateChangePassword = (
  password: string,
  confirmPassword: string,
  passwordStrengthValidated: boolean,
): ChangePasswordValidationModel => {
  return validatePasswordConfirmPassword(
    password,
    confirmPassword,
    passwordStrengthValidated,
  );
};

const validatePasswordConfirmPassword = (
  password: string,
  confirmPassword: string,
  passwordStrengthValidated: boolean,
) => {
  const validationModel: ChangePasswordValidationModel = {
    isValidPassword: true,
    isValidConfirmPassword: true,
    passwordMessage: "",
    confirmPasswordMessage: "",
  };

  //validate password
  if (isEmpty(password)) {
    validationModel.isValidPassword = false;
    validationModel.passwordMessage = i18n.t(keys.please_enter_password);
  } else if (!passwordStrengthValidated) {
    validationModel.isValidPassword = false;
    validationModel.passwordMessage = i18n.t(
      keys.passwords_not_matching_validations,
    );
  }

  //confirm password
  if (isEmpty(confirmPassword)) {
    validationModel.isValidConfirmPassword = false;
    validationModel.confirmPasswordMessage = i18n.t(
      keys.please_enter_confirm_password,
    );
  }

  //passwords not matching
  if (password && confirmPassword && !isEqual(password, confirmPassword)) {
    validationModel.isValidPassword = false;
    validationModel.isValidConfirmPassword = false;
    validationModel.passwordMessage = i18n.t(keys.passwords_not_matching);
    validationModel.confirmPasswordMessage = i18n.t(
      keys.passwords_not_matching,
    );
  }

  return validationModel;
};

export const validateSignup = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string,
  passwordStrengthValidated: boolean,
): SignupValidationModel => {
  var validationModel: SignupValidationModel = {
    isValidFirstName: true,
    firstNameMessage: "",
    isValidLastName: true,
    lastNameMessage: "",
    isValidEmail: true,
    emailMessage: "",
    isValidPassword: true,
    isValidConfirmPassword: true,
    passwordMessage: "",
    confirmPasswordMessage: "",
  };

  if (isEmpty(firstName)) {
    validationModel.isValidFirstName = false;
    validationModel.firstNameMessage = i18n.t(keys.please_enter_firstname);
  }

  if (isEmpty(lastName)) {
    validationModel.isValidLastName = false;
    validationModel.lastNameMessage = i18n.t(keys.please_enter_lastname);
  }

  //validate email
  const emailValidation = validateEmail(email);
  validationModel.isValidEmail = emailValidation.isValid;
  validationModel.emailMessage = emailValidation.message;

  //validate password and confirm password
  const passwordsValidations = validatePasswordConfirmPassword(
    password,
    confirmPassword,
    passwordStrengthValidated,
  );
  validationModel = { ...validationModel, ...passwordsValidations };

  return validationModel;
};
