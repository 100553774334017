import { Divider, TablePagination } from "@mui/material";
import { TablePaginationActions } from "commons";
import { PaginationWrapper } from "commons/styled.components";
import HeaderWithFilter from "components/headerwithfilter";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import { getSurveyListPaginationApi } from "data/slices/surveylist/api";
import {
  SurveyListResponse,
  SurveyListRowsResponse,
} from "data/slices/surveylist/interface";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGE,
  DEFAULT_ROW_OPTIONS_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  SURVEY_STATUS_ASSIGNED,
  SURVEY_STATUS_COMPLETED,
} from "utils/constants";
import { surveysMapper } from "utils/mappers/SurveysListMapper";
import AllSurveys from "widgets/dashboard/surveys/AllSurveys";
import { SurveySetProps } from "widgets/dashboard/surveys/interface";

import { AllSurveysProps } from "../interface";
import {
  ContentWrapper,
  LoaderWrapper,
  TableWrapper,
  Wrapper,
} from "../styled.components";

const AllSurveysContainer = (props: AllSurveysProps) => {
  const {
    showingCompletedSurveys,
    onAllSurveysBackClicked,
    onStartSurveyClicked,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [currentSurveysToDisplay, setCurrentSurveysToDisplay] =
    useState<SurveyListRowsResponse>({ count: 0, rows: [] });

  useEffect(() => {
    //fetch initial list of surveys to display
    getSurveysList(DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSurveysList = async (page: number, pageSize: number) => {
    setLoading(true);

    const surveysListToDisplay: SurveyListResponse =
      await getSurveyListPaginationApi({
        status: showingCompletedSurveys
          ? SURVEY_STATUS_COMPLETED
          : SURVEY_STATUS_ASSIGNED,
        pageSize,
        page,
      });

    setLoading(false);
    if (
      surveysListToDisplay &&
      surveysListToDisplay?.response?.rows?.length >= 0
    ) {
      setCurrentSurveysToDisplay(surveysListToDisplay.response);
    }
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(() => newPage);
    getSurveysList(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getSurveysList(0, newRowsPerPage);
  };

  const surveysToDisplay: SurveySetProps = surveysMapper(
    currentSurveysToDisplay.rows,
    showingCompletedSurveys,
    false,
    currentSurveysToDisplay.count,
  );

  return (
    <>
      <HeaderWithFilter
        title={
          showingCompletedSurveys
            ? t(keys.completed_surveys)
            : t(keys.new_surveys)
        }
        showFilter={false}
        showBackButton={true}
        onBackButtonClickHandler={onAllSurveysBackClicked}
      />
      <Divider />
      <Wrapper>
        <ContentWrapper>
          {loading && (
            <LoaderWrapper>
              <LoadingEmptyError isLoading={loading} />
            </LoaderWrapper>
          )}
          {!loading && (
            <TableWrapper>
              <AllSurveys
                surveysToDisplay={surveysToDisplay}
                onStartSurveyClicked={onStartSurveyClicked}
              />
            </TableWrapper>
          )}
        </ContentWrapper>
        <PaginationWrapper data-testid="pagination-wrapper-all-surveys">
          <TablePagination
            rowsPerPageOptions={DEFAULT_ROW_OPTIONS_PER_PAGE}
            colSpan={3}
            count={currentSurveysToDisplay.count}
            labelRowsPerPage={t(keys.items_per_page)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{
              borderBottom: "none",
            }}
          />
        </PaginationWrapper>
      </Wrapper>
    </>
  );
};

export const ALLSURVEYSPURE = withLoader(AllSurveysContainer);
export default ALLSURVEYSPURE;
