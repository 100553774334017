import styled from "@emotion/styled";
import { css, Typography } from "@mui/material";
import { mobile, tablet } from "commons/styled.components";
import { COLORS } from "utils/colors";

import BalanceItem from "./BalanceItem";

export const BalanceAndGraphWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  gap: 1.25rem;
  grid-auto-rows: minmax(5rem, auto);

  ${tablet(css`
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(7.5rem, auto);
  `)}
`;

export const BalanceItem1 = styled(BalanceItem)`
  grid-column: 1 / 3;
  grid-row: 1;
`;

export const BalanceItem2 = styled(BalanceItem)`
  grid-column: 1 / 2;
  grid-row: 2;
  ${tablet(css`
    grid-column: 1/1;
    grid-row: 2;
  `)}
`;

export const BalanceItem3 = styled(BalanceItem)`
  grid-column: 2 / 2;
  grid-row: 2;
  ${tablet(css`
    grid-column: 2/2;
    grid-row: 2;
  `)}
`;

export const GraphWrapper = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  border-radius: 0.25rem;
  padding: 1.25rem;
  border: 1px solid #e4e4e4;

  ${tablet(css`
    grid-column: 1 / 3;
    grid-row: 3;
  `)}
`;

export const BalanceWrapper = styled.div`
  flex-grow: 1;
`;

export const BalanceItemWrapper = styled.div`
  display: flex;
  padding: 1.25rem 1.25rem 2.5rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  border-radius: 0.25rem;
  border: 1px solid #e4e4e4;
`;

export const BalanceItemIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  ${mobile(css`
    padding-bottom: 0.8rem;
  `)}
`;

export const BalanceItemImage = styled.img`
  width: 2rem;
  height: 2rem;
  background: #f2f2f2;
  padding: 0.25rem;
`;

export const BalanceItemType = styled(Typography)`
  color: #727272;
  font-size: 0.88rem;
  font-weight: 500;
  margin-top: 0.75rem;
`;

export const BalanceItemBalance = styled(Typography)`
  font-size: 1.75rem;
  line-height: 120%;
  font-weight: 500;
  color: #000000;
`;

export const WalletContentWrapper = styled(BalanceItemWrapper)`
  gap: 0.94rem;
  margin-bottom: 1.25rem;
`;

export const UserLevelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;

  ${mobile(css`
    padding-bottom: 0.8rem;
  `)}
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: end;

  ${mobile(css`
    padding-bottom: 0.8rem;
  `)}
`;

export const StepItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StepTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StepCount = styled.p<{ isLast: boolean }>`
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: ${(props) => (props.isLast ? "end" : "start")};
`;

export const TierStep = styled.div<{
  isFirstStep: boolean;
  isLastStep: boolean;
}>`
  height: 10px;
  flex-grow: 1;
  border-bottom-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  border-top-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-top-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  min-width: 4rem;
  background: ${COLORS.BRAND_LIGHT_BACKGROUND};
`;

export const Filler = styled.div<{
  selectedPercent: number;
  isFirstStep: boolean;
  isLastStep: boolean;
}>`
  height: 10px;
  flex-grow: 1;
  width: ${(props) => `${props.selectedPercent}%`};
  border-bottom-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  border-top-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-top-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  background: ${COLORS.BRAND_DEFAULT_COLOR};
`;

export const StarUserLevelText = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
`;

export const NextLevelText = styled(Typography)`
  font-size: 0.88rem;
  font-weight: 400;
  color: #000000;
`;

export const StarTypeText = styled(Typography)`
  color: #000000;
  font-size: 0.88rem;
  font-weight: 500;
`;

export const PopoverWrapper = styled.div`
  display: flex;
  padding: 36px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const RewardItemWrapper = styled.div`
  display: flex;
  gap: 9px;
  width: 100%;
`;

export const RewardItemText = styled.div<{ times?: boolean; align?: string }>`
  color: ${(props) => (props.times ? "#D28E3D" : "#000")};
  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;
  text-align: ${(props) => (props.align ? props.align : "end")};
`;

export const PointsEarnedText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  height: 1.5rem;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.5rem;
  border: 1px solid #e4e4e4;
  background: white;
`;

export const TooltipPointsWrapper = styled.span`
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  background: #f9f9f9;
  padding: 8px 12px;
`;

export const TooltipText = styled(Typography)`
  color: #727272;
  font-size: 14px;
  font-weight: 500;
`;

export const YAxisTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GraphYAxisLabelText = styled(Typography)`
  margin: 0;
  font-size: 12px;
  text-align: center;
  transform: rotate(270deg);
  height: 2.5rem;
  text-align: center;
  width: 8px;
`;

export const GraphXAxisLabelText = styled(Typography)`
  text-align: center;
  font-size: 12px;
`;
