import { KeyboardArrowRightRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";

import Earnings from "../../../assets/icons/Database.svg";
import Gift from "../../../assets/icons/Gift.svg";
import Wallet from "../../../assets/icons/wallet.svg";
import { BALANCE_TYPE, BalanceItemProps } from "./interface";
import {
  BalanceItemBalance,
  BalanceItemIconWrapper,
  BalanceItemImage,
  BalanceItemType,
  BalanceItemWrapper,
} from "./styled.components";

const BalanceItem = (props: BalanceItemProps) => {
  const { points, balanceType, onClickHandler } = props;
  const { t } = useTranslation();

  var type;
  var icon;
  if (balanceType === BALANCE_TYPE.BALANCE) {
    type = t(keys.total_balance);
    icon = Wallet;
  } else if (balanceType === BALANCE_TYPE.EARNINGS) {
    type = t(keys.earnings);
    icon = Earnings;
  } else {
    type = t(keys.redeemed);
    icon = Gift;
  }

  const onArrowClickHandler = () => {
    onClickHandler?.();
  };

  return (
    <BalanceItemWrapper {...props}>
      <BalanceItemIconWrapper>
        <BalanceItemImage src={icon} alt={type} />
        {balanceType === BALANCE_TYPE.EARNINGS && (
          <IconButton onClick={onArrowClickHandler}>
            <KeyboardArrowRightRounded
              fontSize="small"
              style={{ color: "black" }}
            />
          </IconButton>
        )}
      </BalanceItemIconWrapper>
      <BalanceItemType>{type}</BalanceItemType>
      <BalanceItemBalance>{points}</BalanceItemBalance>
    </BalanceItemWrapper>
  );
};

export default BalanceItem;
