export const getBaseURL = () => {
  return String(process.env.REACT_APP_API_SERVICE_URL);
};

export const ENDPOINTS = {
  //user api's
  LOGIN: "/User/login",
  FORGOT_PASSWORD: "/User/forgotPassword",
  SIGNUP: "/User/create",
  RESET_PASSWORD: "/User/resetPassword",
  DEACTIVATE: "/User/deactivate",
  USER_DETAILS: "/User/view",
  UPDATE_USER_DETAILS: "/User/update",
  REFRESH_TOKEN: "/User/refreshToken/",
  CHANGE_PASSWORD: "/User/changePassword",
  INVITATION_DETAILS: "/UserInvitaion/viewByHash/",

  //wallet
  WALLET_DETAILS: "/wallet/view",
  ACHIEVEMENT_TIER_LIST: "/AchievementTier/list",
  COINS_HISTORY: "/CoinsHistory/list/",

  //survey
  USER_SURVEY: "/UserSurvey/list",
  INCREASE_CLICKS: "/UserSurvey/increaseClicks/",
  UPDATE_SURVEY: "/UserSurvey/updateSurveyAndRewards",

  //gift cards and cart
  GIFTCARDS: "/MasterGiftCard/list",
  MASTER_FLEXI_CARD: "/MasterGiftCard/viewFlexiCard",
  CART_ADDITEM: "/Cart/addItem",
  CART_COUNT: "/Cart/count",
  CART_LIST: "/Cart/view",
  CART_DELETE_ITEM: "/Cart/",
  CART_PURCHASE: "/Cart/purchase",
  CART_QUANTITY: "/Cart/increateOrDecreaseQuantity/",

  //support
  SUPPORT_CREATE: "/Support/create",
  SUPPORT_LIST: "/Support/list",
  SUPPORT_UPDATE_STATUS: "/Support/update/",

  //user invitation
  INVITATION_SINGLE: "/UserInvitaion/create",
  INVITATION_LIST: "/UserInvitaion/list",

  //common
  UPLOAD: "/Common/Upload",

  //geo info
  GEO_INFO: "https://ipapi.co/json/",
};
