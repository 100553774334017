import { GiftCardDetails } from "data/slices/giftcards/interface";
import { isEmpty, isUndefined } from "lodash";
import { appendPoints } from "utils/Utils";
import { CouponProps } from "widgets/dashboard/redeempoints/interface";

export const getCoupons = (
  giftcards: GiftCardDetails[],
): Map<string, CouponProps[]> => {
  const coupons: Map<string, CouponProps[]> = new Map();

  if (giftcards) {
    //TODO: this duplicate should be removed from server
    //remove duplicate giftcards, based on giftId
    const uniqueList = new Set();
    const uniqueGiftcards: GiftCardDetails[] = [];
    giftcards.forEach((item) => {
      if (!uniqueList.has(item.giftId)) {
        uniqueList.add(item.giftId);
        uniqueGiftcards.push(item);
      }
    });

    uniqueGiftcards.forEach((card) => {
      const coupon: CouponProps = {
        id: card.id,
        description: card.description,
        giftId: card.giftId,
        imageURL: card.imageURL,
        inStock: card.inStock,
        terms: card.terms,
        title: card.title,
        validity: card.validity,
        providerId: card.providerId,
        coins: appendPoints(card.coins),
        value: `£${card.value}`,
      };

      if (coupons.has(card.imageURL)) {
        const existingCoupon = coupons.get(card.imageURL);
        existingCoupon.push(coupon);
      } else {
        coupons.set(card.imageURL, [coupon]);
      }
    });
  }

  return coupons;
};

export const searchCoupons = (
  groupedCoupons: Map<string, CouponProps[]>,
  searchText: string,
): Map<string, CouponProps[]> => {
  if (isEmpty(searchText) || isUndefined(searchText)) {
    return groupedCoupons;
  }

  const coupons: Map<string, CouponProps[]> = new Map();

  if (groupedCoupons) {
    Array.from(groupedCoupons).forEach(([key, value]) => {
      if (value[0].title.toLowerCase().includes(searchText.toLowerCase())) {
        coupons.set(key, value);
      }
    });
  }

  return coupons;
};
