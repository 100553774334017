import { AchievementTierDetails } from "data/slices/wallet/interface";

export const getCurrentUserLevel = (
  achievementTierDetails: AchievementTierDetails[],
  completedSurveys: number,
) => {
  var level = 0;
  if (completedSurveys === 0) {
    return 0;
  }

  const length = achievementTierDetails.length;
  if (completedSurveys >= achievementTierDetails[length - 1]?.maxSurvays) {
    return length - 1; //max tier
  }

  for (let i = 0; i < length; i++) {
    if (
      completedSurveys >= achievementTierDetails[i]?.minSurvays &&
      completedSurveys <= achievementTierDetails[i]?.maxSurvays
    ) {
      level = i;
    }
  }
  return level;
};

export const getNextLevelText = (
  achievementTierDetails: AchievementTierDetails[],
  userLevel: number,
  completedSurveys: number,
) => {
  const completedText = `You have ${completedSurveys} surveys completed.`;

  if (userLevel === 0) {
    return `${completedText} Get ${achievementTierDetails[0]?.maxSurvays + 1 - completedSurveys} more surveys done to become a ${achievementTierDetails[1]?.name} member`;
  } else if (userLevel === achievementTierDetails.length - 1) {
    return completedText;
  } else {
    return `${completedText} Get ${achievementTierDetails[userLevel]?.maxSurvays - completedSurveys} more surveys done to become a ${achievementTierDetails[userLevel + 1]?.name} member`;
  }
};
