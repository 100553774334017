import styled from "@emotion/styled";
import { Button, css, Stack, Typography } from "@mui/material";
import { mobile, tablet } from "commons/styled.components";
import { COLORS } from "utils/colors";

export const Wrapper = styled.div<{ topMargin: string }>`
  margin-top: ${(props) => props.topMargin};
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const SurveyItemWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e4e4e4;

  ${mobile(css`
    align-items: start;
    flex-direction: column;
  `)}
`;

export const SurveyHeaderWrapper = styled(SurveyItemWrapper)`
  background: ${COLORS.BRAND_LIGHT_BACKGROUND};
  border: none;
  color: rgb(0, 0, 0);
  width: 100% !important;
`;

export const TableWrapper = styled.div`
  ${tablet(css`
    min-width: 120% !important;
  `)}
`;
export const HeaderItem = styled(Typography)<{
  index: number;
  isbuttoncolumn?: boolean;
}>`
  flex-basis: 0;
  flex-grow: ${(props) => (props.isbuttoncolumn ? 0 : 1)};
  font-size: 1rem !important;
  font-weight: 400;
`;

export const ItemText = styled(Typography)<{ issurveyname?: boolean }>`
  flex-basis: 0;
  flex-grow: ${(props) => (props.issurveyname ? 2 : 1)};
  font-size: ${(props) => (props.issurveyname ? "1.25rem" : "0.88rem")};
  font-weight: ${(props) => (props.issurveyname ? 500 : 400)};
  color: ${(props) => (props.issurveyname ? "#000000" : "#727272")};
  font-weight: ${(props) => (props.issurveyname ? 500 : 400)};
  text-wrap: wrap;
`;

export const StackItem = styled(Stack)`
  flex-basis: 0;
  flex-grow: 1;
`;

export const PointsItemWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

export const StartWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 0;
`;

export const PointsItem = styled.span`
  border-radius: 4px;
  background: #f7f7e8;
  font-size: 0.88rem;
  color: #ff9f02;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;

  ${mobile(css`
    font-size: 14px;
  `)}
`;

export const TasksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100% !important;
`;

export const ViewMoreWrapper = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding-left: 8px;
`;

export const ViewMoreText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

export const SetHeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100% !important;
`;

export const Indicator = styled.span<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.color};
`;

export const SetTile = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const MobileLabel = styled(Typography)`
  font-size: 0.75rem;
`;

export const SetStatus = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #727272;
  padding-top: 2px;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
`;

export const BannerTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  color: black;
`;

export const ViewMoreStack = styled(Stack)`
  margin-left: auto;
  align-items: center;
  cursor: pointer;
`;

export const StartButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid ${COLORS.BRAND_DEFAULT_COLOR};
  color: #000000;
  text-transform: none;
  background-color: transparent;
  padding: 4px 20px;
  font-size: 0.75rem;

  :hover {
    border: 1px solid ${COLORS.BRAND_DEFAULT_COLOR};
  }
`;

export const ProfileCompletionButton = styled(Button)`
  border-radius: 4px;
  border: 1px solid #000000;
  color: #000000;
  padding: 10px 16px;
  text-transform: none;
  background-color: transparent;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const ProfileCompletionPointsText = styled(Typography)`
  color: #af4b4b;
  font-size: 12px;
  font-weight: 500;
  background: #f7eded;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;

export const SurveysTableWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto !important;
  width: 100% !important;

  &::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const TableContentWrapper = styled.div`
  min-width: 750px;
`;

export const NotificationBannerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NotificationDateLabel = styled(Typography)`
  font-weight: 400;
  font-size: 0.75rem;
  color: #727272;
`;

export const NotificationTitleLabel = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
`;

export const NotificationDescriptionLabel = styled(Typography)`
  font-weight: 400;
  font-size: 0.88rem;
  color: #727272;
`;
