import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { CartCountActions } from ".";
import { getCartCountApi } from "./api";

export default function* cartcount(): SagaIterator {
  try {
    const cartcountResponse = yield call(getCartCountApi);
    const { data } = cartcountResponse;
    yield put(CartCountActions.success(data?.response.count));
  } catch (e) {
    yield put(CartCountActions.error(e?.response?.data?.message));
  }
}
