import Label from "components/label";
import { appendPoints } from "utils/Utils";

import wallet from "../../assets/icons/wallet.svg";
import { WalletBalanceProps } from "./interface";
import { WalletImageWrapper, WalletWrapper } from "./styled.components";

const WalletBalance = (props: WalletBalanceProps) => {
  return (
    <WalletWrapper {...props}>
      <WalletImageWrapper src={wallet} alt="wallet" />
      <Label type="normalbold">{appendPoints(parseFloat(props.balance))}</Label>
    </WalletWrapper>
  );
};

export default WalletBalance;
