import { useMediaQuery, useTheme } from "@mui/material";
import { GenericTableProps, TableItemProps } from "commons/interface";
import { isEqual } from "lodash";
import {
  TABLE_TYPE_ORDERS,
  TABLE_TYPE_REFERALS,
  TABLE_TYPE_RETAILERS,
  TABLE_TYPE_WALLET_EARNINGS,
} from "utils/constants";

import MobileTableItem from "./MobileTableItem";
import { GenericTableWrapper, TableWrapper } from "./styled.components";
import TableHeader from "./TableHeader";
import TableItem from "./TableItem";

const GenericTable = (props: GenericTableProps) => {
  const { headerItems, tableItems, tableType, onActionButtonClicked } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasMobileSupport =
    isEqual(tableType, TABLE_TYPE_ORDERS) ||
    isEqual(tableType, TABLE_TYPE_WALLET_EARNINGS) ||
    isEqual(tableType, TABLE_TYPE_RETAILERS) ||
    isEqual(tableType, TABLE_TYPE_REFERALS);

  if (isMobile && hasMobileSupport) {
    return (
      <TableWrapper>
        <GenericTableWrapper>
          {tableItems.map((item: TableItemProps, index) => {
            return (
              <MobileTableItem
                key={index}
                {...item}
                tableType={tableType}
                onActionButtonClicked={onActionButtonClicked}
                headerItems={headerItems}
              ></MobileTableItem>
            );
          })}
        </GenericTableWrapper>
      </TableWrapper>
    );
  }

  return (
    <TableWrapper>
      <GenericTableWrapper>
        <TableHeader headerItems={headerItems} />
        {tableItems.map((item: TableItemProps, index) => {
          return (
            <TableItem
              key={index}
              {...item}
              tableType={tableType}
              onActionButtonClicked={onActionButtonClicked}
            ></TableItem>
          );
        })}
      </GenericTableWrapper>
    </TableWrapper>
  );
};

export default GenericTable;
