import { MenuItem } from "@mui/material";
import CustomSnackbar from "components/snackbar";
import { addCouponToCartApi } from "data/slices/cart/api";
import { CartGenericResponse } from "data/slices/cart/interface";
import { CartCountActions } from "data/slices/cartcount";
import FullScreenLoader from "hoc/loader/loader";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SNACKBAR_TYPE } from "utils/constants";

import CouponPlaceholder from "../../../assets/icons/CouponPlaceholder.svg";
import { CouponDetailsProps } from "./interface";
import {
  AddtoCartButton,
  CardContent,
  CouponCard,
  CouponImage,
  CouponName,
  CouponValueWrapper,
  StockInfo,
  StockText,
  ValidityText,
  ValueSelect,
} from "./styled.components";

//Add to cart api is called here only, since adding in container is refreshing the page and is taking time to reload again
//TODO: use memo to optimize the re-renders ?
const CouponDetails = (props: CouponDetailsProps) => {
  const { coupons } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(0);
  const dispatch = useDispatch();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });

  const { id, title, validity, imageURL, inStock } = coupons[selectedCoupon];

  const couponValues = [];
  coupons.forEach((coupon) => {
    couponValues.push(`${coupon.coins} \\ ${coupon.value}`);
  });

  const onAddCouponToCart = (e: any) => {
    e.stopPropagation();
    addToCart(id, 1);
  };

  const addToCart = async (id: number, quantity: number) => {
    setIsLoading(true);
    const response: CartGenericResponse = await addCouponToCartApi({
      masterGiftCard: id,
      quantity,
    });

    setIsLoading(false);
    handleResponse(response);
  };

  const handleResponse = (response: CartGenericResponse) => {
    if (isUndefined(response) || isUndefined(response.message)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (!isUndefined(response.error)) {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    } else {
      //increment the global counter
      dispatch(CartCountActions.request());
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.SUCCESS);
    }
  };

  const onShowSnackbarHandler = (msg: string, type: number) => {
    setShowSnackbar(true);
    setSnackbarMsg({ msg, type });
  };

  const changeCouponValue = (event: any) => {
    const selectedValue = event.target.value;
    const selectedIndex = couponValues.map((x) => x).indexOf(selectedValue);
    setSelectedCoupon(selectedIndex);
  };

  return (
    <>
      <CouponCard>
        <CardContent>
          <CouponImage
            src={imageURL}
            alt=""
            loading="lazy"
            onError={(e) => {
              e.currentTarget.src = CouponPlaceholder;
            }}
          />
          <CouponName noWrap>{title}</CouponName>
          <CouponValueWrapper>
            <ValueSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={couponValues[selectedCoupon]}
              onChange={changeCouponValue}
              size="small"
              sx={{
                "& .MuiSelect-select": {
                  padding: "4px 6px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
              }}
            >
              {couponValues.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </ValueSelect>
            <AddtoCartButton onClick={onAddCouponToCart} variant="outlined">
              {t(keys.add)}
            </AddtoCartButton>
          </CouponValueWrapper>
          <ValidityText>{`Val: ${validity} months`}</ValidityText>
          <StockInfo instock={inStock}>
            <StockText instock={inStock}>
              {inStock ? t(keys.in_stock) : t(keys.no_stock)}
            </StockText>
          </StockInfo>
        </CardContent>
      </CouponCard>
      {isLoading && <FullScreenLoader />}
      <CustomSnackbar
        msg={snackbarMsg.msg}
        type={snackbarMsg.type}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
    </>
  );
};

export default CouponDetails;
