import { useMediaQuery, useTheme } from "@mui/material";

import { AllSurveysProps } from "./interface";
import SurveySet from "./SurveySet";

const AllSurveys = (props: AllSurveysProps) => {
  const { surveysToDisplay, onStartSurveyClicked } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SurveySet
      {...surveysToDisplay}
      showAllSurveys={true}
      onStartSurveyClicked={onStartSurveyClicked}
      isMobile={isMobile}
    />
  );
};

export default AllSurveys;
