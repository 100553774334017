import i18n from "i18n";
import keys from "locale/keys";
import { isEmpty } from "lodash";
import { isValidAge } from "utils/Utils";
import { ChangePasswordValidationModel } from "widgets/prelogin/interface";

import { ProfileDataToSave, ValidationModel } from "./interface";

export const ValidateProfileDetails = (
  profileData: ProfileDataToSave,
): ValidationModel => {
  const validationModel: ValidationModel = {
    isValidFirstName: true,
    firstNameMessage: "",
    isValidLastName: true,
    lastNameMessage: "",
    isValidEthnicity: true,
    ethnicityMessage: "",
    isValidPostCode: true,
    postCodeMessage: "",
    isValidDOB: true,
    DOBMessage: "",
    isValidGender: true,
    genderMessage: "",
  };

  if (isEmpty(profileData.firstName)) {
    validationModel.isValidFirstName = false;
    validationModel.firstNameMessage = i18n.t(keys.please_enter_firstname);
  } else {
    const name = profileData.firstName;
    const NAME_REGEX = /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/;
    /** Validates a name field (first or last name) */
    const isValidName = NAME_REGEX.test(name);

    if (!isValidName) {
      validationModel.isValidFirstName = false;
      validationModel.firstNameMessage = i18n.t(keys.please_enter_validname);
    }
  }

  if (isEmpty(profileData.lastName)) {
    validationModel.isValidLastName = false;
    validationModel.lastNameMessage = i18n.t(keys.please_enter_lastname);
  } else {
    const name = profileData.lastName;
    const NAME_REGEX = /^[a-zA-Z]+([ \-']{0,1}[a-zA-Z]+){0,2}[.]{0,1}$/;
    /** Validates a name field (first or last name) */
    const isValidName = NAME_REGEX.test(name);

    if (!isValidName) {
      validationModel.isValidFirstName = false;
      validationModel.firstNameMessage = i18n.t(keys.please_enter_validname);
    }
  }

  if (isEmpty(profileData.ethnicity)) {
    validationModel.isValidEthnicity = false;
    validationModel.ethnicityMessage = i18n.t(keys.please_enter_ethnicity);
  }

  if (isEmpty(profileData.postCode)) {
    validationModel.isValidPostCode = false;
    validationModel.postCodeMessage = i18n.t(keys.please_enter_postcode);
  }

  if (isEmpty(profileData.dateOfBirth)) {
    validationModel.isValidDOB = false;
    validationModel.DOBMessage = i18n.t(keys.please_select_DOB);
  } else if (!isValidAge(new Date(profileData.dateOfBirth))) {
    validationModel.isValidDOB = false;
    validationModel.DOBMessage = i18n.t(keys.please_select_valid_date);
  }

  if (isEmpty(profileData.gender)) {
    validationModel.isValidGender = false;
    validationModel.genderMessage = i18n.t(keys.please_select_gender);
  }

  return validationModel;
};

export const ValidateChangeUserPassword = (
  oldPassword: string,
  confirmPassword: string,
  passwordStrengthValidated: boolean,
): ChangePasswordValidationModel => {
  const validationModel: ChangePasswordValidationModel = {
    isValidPassword: true,
    isValidConfirmPassword: true,
    passwordMessage: "",
    confirmPasswordMessage: "",
  };

  //confirm password
  if (isEmpty(oldPassword)) {
    validationModel.isValidPassword = false;
    validationModel.passwordMessage = i18n.t(keys.please_enter_old_password);
  }

  //validate password
  if (isEmpty(confirmPassword)) {
    validationModel.isValidConfirmPassword = false;
    validationModel.confirmPasswordMessage = i18n.t(
      keys.please_enter_confirm_password,
    );
  } else if (!passwordStrengthValidated) {
    validationModel.isValidConfirmPassword = false;
    validationModel.confirmPasswordMessage = i18n.t(
      keys.passwords_not_matching_validations,
    );
  }

  return validationModel;
};
