import styled from "@emotion/styled";
import { css, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  justify-content: center;
  width: 100%;
`;

export const AppLogoImg = styled.img`
  width: 40%;
  margin-bottom: 4rem;

  ${mobile(css`
    width: 60%;
  `)}
`;

export const Message = styled(Typography)`
  font-size: 16px;
  padding: 1rem 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;
