import { TableHeaderProps } from "./interface";
import { HeaderItem, TableHeaderWrapper } from "./styled.components";

const TableHeader = (props: TableHeaderProps) => {
  const { headerItems } = props;

  if (!headerItems || headerItems?.length === 0) {
    return <></>;
  }

  return (
    <TableHeaderWrapper data-testid="table-header-wrapper">
      {headerItems.map((item, index) => {
        return <HeaderItem key={index}>{item}</HeaderItem>;
      })}
    </TableHeaderWrapper>
  );
};

export default TableHeader;
