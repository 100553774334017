import { PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { SURVEY_STATUS_ASSIGNED } from "utils/constants";

import { SurveyListActions } from ".";
import { getSurveyListApi } from "./api";
import { SurveyListRequest } from "./interface";

export default function* surveyList(
  request: PayloadAction<SurveyListRequest>,
): SagaIterator {
  try {
    const { payload } = request;
    const surveysResponse = yield call(getSurveyListApi, payload);
    const { data } = surveysResponse;

    if (isEqual(payload.status, SURVEY_STATUS_ASSIGNED)) {
      yield put(SurveyListActions.updateNewSurveys(data?.response));
    } else {
      yield put(SurveyListActions.updateCompletedSurveys(data?.response));
    }
  } catch (e) {
    yield put(SurveyListActions.error(e?.response?.data?.message));
  }
}
