import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GiftCardsRequest, initialState } from "./interface";

export const GiftCardsSlice = createSlice({
  name: "giftcards",
  initialState: initialState,
  reducers: {
    request: (state, action: PayloadAction<GiftCardsRequest>) => {
      state.isFetching = true;
      state.isError = false;
      state.fetchedCards = false;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.isError = false;
      state.giftcards = action.payload;
      state.fetchedCards = true;
    },
    error: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.giftcards = { count: 0, rows: [] };
      state.fetchedCards = false;
    },
  },
});

export const { actions: GiftCardsActions } = GiftCardsSlice;

export default GiftCardsSlice.reducer;
