import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { NotificationBannerProps } from "./interface";
import {
  BannerWrapper,
  NotificationBannerWrapper,
  NotificationDateLabel,
  NotificationDescriptionLabel,
  NotificationTitleLabel,
} from "./styled.components";

const NotificationBanner = (props: NotificationBannerProps) => {
  const { title, description, date, dismissNotification } = props;

  return (
    <BannerWrapper>
      <NotificationBannerWrapper data-testid="notification-banner">
        <NotificationDateLabel>{date}</NotificationDateLabel>
        <NotificationTitleLabel>{title}</NotificationTitleLabel>
        <NotificationDescriptionLabel>
          {description}
        </NotificationDescriptionLabel>
      </NotificationBannerWrapper>
      <IconButton onClick={() => dismissNotification()}>
        <Close />
      </IconButton>
    </BannerWrapper>
  );
};

export default NotificationBanner;
