import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

export const deactivateAccountApi = async () => {
  try {
    const { data } = await axios.get(getBaseURL() + ENDPOINTS.DEACTIVATE, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};
