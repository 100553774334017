import { GenericTableProps, TableItemProps } from "commons/interface";
import { CoinsHistoryItem } from "data/slices/coinshistory/interface";
import {
  DEFAULT_PLACEAHOLDER,
  EARNINGS_DATE_FORMAT,
  TABLE_TYPE_ORDERS,
} from "utils/constants";
import {
  appendPoints,
  formatDate,
  getStringWithDefaultValue,
} from "utils/Utils";

export const getOrders = (
  coinshistory: CoinsHistoryItem[],
): GenericTableProps => {
  const coins: TableItemProps[] = [];

  if (coinshistory) {
    coinshistory.forEach((coin) => {
      coins.push({
        transactionid: getStringWithDefaultValue(coin.transactionId),
        couponoff: getStringWithDefaultValue(coin.masterGiftCard?.title),
        value: coin.masterGiftCard?.value
          ? `£${coin.masterGiftCard.value}`
          : DEFAULT_PLACEAHOLDER,
        points: appendPoints(coin.coins),
        dateofpurchase: formatDate(coin.createdAt, EARNINGS_DATE_FORMAT),
        status: coin.transactionStatus,
      });
    });
  }

  return {
    tableType: TABLE_TYPE_ORDERS,
    headerItems: getHeaders(),
    tableItems: coins,
  };
};

const getHeaders = () => {
  return [
    "Transaction ID",
    "Coupon Title",
    "Value",
    "Points Redeemed",
    "Date of Purchase",
    "Status",
  ];
};
