import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
} from "@mui/material";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import keys from "locale/keys";
import { isEqual, isUndefined } from "lodash";
import { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { POINTS_FILTER, POINTS_FILTER_BY } from "utils/constants";
import { walletBalance } from "utils/Utils";

import CurrentLevel from "../../../assets/icons/CurrentLevel.svg";
import LockedLevel from "../../../assets/icons/LockedLevel.svg";
import Star from "../../../assets/icons/Star.svg";
import { LoaderWrapper } from "../surveys/styled.components";
import BalanceChart from "./BalanceChart";
import { BALANCE_TYPE, WalletProps } from "./interface";
import RewardsPopover from "./RewardsPopover";
import {
  BalanceAndGraphWrapper,
  BalanceItem1,
  BalanceItem2,
  BalanceItem3,
  BalanceItemIconWrapper,
  BalanceItemImage,
  Filler,
  FilterWrapper,
  GraphWrapper,
  GraphXAxisLabelText,
  GraphYAxisLabelText,
  NextLevelText,
  PointsEarnedText,
  StarTypeText,
  StarUserLevelText,
  StepCount,
  StepItemWrapper,
  StepTextWrapper,
  StepWrapper,
  TierStep,
  UserLevelsWrapper,
  WalletContentWrapper,
  YAxisTextWrapper,
} from "./styled.components";
import { getCurrentUserLevel, getNextLevelText } from "./utils";

//Handle empty and error states
const MyWallet = (props: WalletProps) => {
  const {
    isFetching,
    walletDetails,
    onShowEarningsLogHandler,
    completedSurveys,
    chartData,
    isGraphsLoading,
    isAchievementsLoading,
    achievementTierDetails,
    updateGraphsData,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [pointsFilter, setPointsFilter] = useState(0);

  const currentUserLevel = getCurrentUserLevel(
    achievementTierDetails,
    completedSurveys,
  );

  const showSteps = () => {
    const length = achievementTierDetails.length;
    if (length === 0) {
      return [];
    }

    const arr = [];
    for (let i = 0; i < length; i++) {
      const isSelected = currentUserLevel >= i;
      const selectedPercent =
        (completedSurveys / achievementTierDetails[i]?.maxSurvays) * 100;
      arr.push(
        <StepItemWrapper key={i}>
          {i === 0 && (
            <StepTextWrapper>
              <StepCount isLast={false}>{0}</StepCount>
              <StepCount isLast={true}>
                {achievementTierDetails[i]?.maxSurvays}
              </StepCount>
            </StepTextWrapper>
          )}
          {i !== 0 && (
            <StepCount isLast={true}>
              {achievementTierDetails[i]?.maxSurvays}
            </StepCount>
          )}
          <TierStep
            isFirstStep={isEqual(i, 0)}
            isLastStep={isEqual(i, length - 1)}
          >
            {isSelected && (
              <Filler
                selectedPercent={selectedPercent}
                isFirstStep={isEqual(i, 0)}
                isLastStep={isEqual(i, length - 1)}
              />
            )}
          </TierStep>
        </StepItemWrapper>,
      );
    }
    return arr;
  };

  const showUserLevels = () => {
    const isEmpty = achievementTierDetails.length === 0;
    if (isEmpty) {
      return [];
    }

    const arr = [];
    for (let i = 0; i < achievementTierDetails.length; i++) {
      arr.push(
        <Stack direction="row" alignItems="center" gap={0.8} key={i}>
          <img
            src={currentUserLevel >= i ? CurrentLevel : LockedLevel}
            alt={`${currentUserLevel}`}
          />
          <StarTypeText>
            {achievementTierDetails[i].name.toUpperCase()}
          </StarTypeText>
        </Stack>,
      );
    }
    return arr;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    setPointsFilter(event.target.value);

    //fet new data based on filter type
    var type = POINTS_FILTER_BY.WEEK;
    if (event.target.value === 0) {
      type = POINTS_FILTER_BY.WEEK;
    } else if (event.target.value === 1) {
      type = POINTS_FILTER_BY.MONTH;
    } else {
      type = POINTS_FILTER_BY.QUARTER;
    }

    updateGraphsData(type);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (isFetching) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  const showAchievementtierDetails = () => {
    const isEmpty = achievementTierDetails.length === 0;
    if (isAchievementsLoading || isEmpty) {
      return (
        <WalletContentWrapper>
          <StarUserLevelText>
            {t(keys.star_user_levels).toUpperCase()}
          </StarUserLevelText>
          <LoaderWrapper data-testid="tier-loader-wrapper">
            <LoadingEmptyError
              isEmpty={isEmpty}
              isError={false}
              isLoading={isAchievementsLoading}
            />
          </LoaderWrapper>
        </WalletContentWrapper>
      );
    }

    return (
      <WalletContentWrapper>
        <BalanceItemIconWrapper>
          <BalanceItemImage src={Star} alt="star" />
          <IconButton onClick={handleClick}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </BalanceItemIconWrapper>
        <UserLevelsWrapper>
          <StarUserLevelText>
            {t(keys.star_user_levels).toUpperCase()}
          </StarUserLevelText>
          {showUserLevels()}
        </UserLevelsWrapper>
        <StepWrapper>{showSteps()}</StepWrapper>
        <NextLevelText>
          {getNextLevelText(
            achievementTierDetails,
            currentUserLevel,
            completedSurveys,
          )}
        </NextLevelText>
      </WalletContentWrapper>
    );
  };

  const displayPointsEarnedGraph = () => {
    const isEmpty =
      isUndefined(chartData) || (chartData && chartData.length === 0);
    if (isGraphsLoading || isEmpty) {
      return (
        <GraphWrapper>
          <PointsEarnedText>{t(keys.points_earned)}</PointsEarnedText>
          <LoadingEmptyError
            isEmpty={isEmpty}
            isError={false}
            isLoading={isGraphsLoading}
          />
        </GraphWrapper>
      );
    }

    return (
      <GraphWrapper>
        <PointsEarnedText>{t(keys.points_earned)}</PointsEarnedText>
        <FilterWrapper>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={pointsFilter}
            onChange={handleChange}
            sx={{
              height: "30px",
            }}
          >
            <MenuItem value={0}>{POINTS_FILTER.WEEK}</MenuItem>
            <MenuItem value={1}>{POINTS_FILTER.MONTH}</MenuItem>
            <MenuItem value={2}>{POINTS_FILTER.QUARTER}</MenuItem>
          </Select>
        </FilterWrapper>
        <YAxisTextWrapper>
          <GraphYAxisLabelText>Coins</GraphYAxisLabelText>
          <BalanceChart chartData={chartData} />
        </YAxisTextWrapper>
        <GraphXAxisLabelText>In days</GraphXAxisLabelText>
      </GraphWrapper>
    );
  };

  return (
    <>
      {showAchievementtierDetails()}
      <BalanceAndGraphWrapper data-testid="mywallet-balance-graph-wrapper">
        <BalanceItem1
          balanceType={BALANCE_TYPE.BALANCE}
          points={walletBalance(walletDetails)}
        />
        <BalanceItem2
          balanceType={BALANCE_TYPE.EARNINGS}
          points={walletDetails.earnings}
          onClickHandler={onShowEarningsLogHandler}
        />
        <BalanceItem3
          balanceType={BALANCE_TYPE.REDEEMED}
          points={walletDetails.redeemed}
        />
        {displayPointsEarnedGraph()}
      </BalanceAndGraphWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <RewardsPopover achievementTierDetails={achievementTierDetails} />
      </Popover>
    </>
  );
};

export default MyWallet;
