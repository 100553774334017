import { Snackbar, useMediaQuery, useTheme } from "@mui/material";
import ChangePasswordContainer from "containers/prelogin/changepassword";
import ForgotPasswordContainer from "containers/prelogin/forgotpassword";
import LoginContainer from "containers/prelogin/login";
import SignupContainer from "containers/prelogin/signup";
import * as React from "react";
import {
  PRELOGIN_INDEX,
  SNACKBAR_AUTO_DISMISS_DURATION_MS,
} from "utils/constants";

import { PreloginProps } from "./interface";
import { RightPanelWrapper, StyledAlert } from "./styled.components";

const RightPanel = (props: PreloginProps) => {
  const { index } = props;
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const onShowSnackbarHandler = (msg: string) => {
    setShowSnackbar(true);
    setSnackbarMsg(msg);
  };

  const getScreen = () => {
    if (index === PRELOGIN_INDEX.LOGIN) {
      return <LoginContainer onShowSnackbarHandler={onShowSnackbarHandler} />;
    } else if (index === PRELOGIN_INDEX.FORGOT_PASSWORD) {
      return (
        <ForgotPasswordContainer
          onShowSnackbarHandler={onShowSnackbarHandler}
        />
      );
    } else if (index === PRELOGIN_INDEX.CHANGE_PASSWORD) {
      return (
        <ChangePasswordContainer
          onShowSnackbarHandler={onShowSnackbarHandler}
        />
      );
    } else if (index === PRELOGIN_INDEX.SIGNUP) {
      return <SignupContainer onShowSnackbarHandler={onShowSnackbarHandler} />;
    }
  };

  return (
    <RightPanelWrapper>
      {getScreen()}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={SNACKBAR_AUTO_DISMISS_DURATION_MS}
        open={showSnackbar}
        onClose={handleCloseSnackbar}
        sx={{
          width: "100%",
        }}
      >
        <StyledAlert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{
            width: isMobile ? "60%" : "40%",
            marginLeft: isMobile ? "0%" : "32%",
          }}
        >
          {snackbarMsg}
        </StyledAlert>
      </Snackbar>
    </RightPanelWrapper>
  );
};

export default RightPanel;
