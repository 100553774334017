import { ArrowForwardIosOutlined } from "@mui/icons-material";
import { Card, CardActionArea, IconButton } from "@mui/material";
import {
  MobileItemDescription,
  MobileItemValue,
} from "components/table/styled.components";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";
import { appendPoints } from "utils/Utils";

import calendar from "../../../assets/icons/calendar.svg";
import { SurveyItemProps } from "./interface";
import {
  ItemText,
  PointsItem,
  PointsItemWrapper,
  StackItem,
  StartButton,
  StartWrapper,
  SurveyItemWrapper,
} from "./styled.components";

const SurveyItem = (props: SurveyItemProps) => {
  const {
    surveyNumber,
    id,
    timeLeft = "",
    points,
    isCompletedSurveys,
    completedDate,
    url,
    providerKey,
    onStartSurveyClicked,
    isMobile,
    timeToComplete,
    status,
  } = props;
  const { t } = useTranslation();

  const onStartClicked = () => {
    onStartSurveyClicked?.(url, providerKey, id);
  };

  if (isMobile) {
    return (
      <Card>
        <CardActionArea onClick={() => onStartClicked()}>
          <SurveyItemWrapper>
            <StackItem direction="row" alignItems="center" gap={1} width="100%">
              <ItemText issurveyname={true}>{surveyNumber}</ItemText>
              <IconButton>
                <ArrowForwardIosOutlined fontSize="small"></ArrowForwardIosOutlined>
              </IconButton>
            </StackItem>
            <table>
              <tr>
                <MobileItemDescription>
                  {isCompletedSurveys
                    ? `${t(keys.completed_date)} : `
                    : `${t(keys.end_date)} : `}
                </MobileItemDescription>
                <MobileItemValue>
                  <StackItem direction="row" alignItems="center" gap={0}>
                    <img src={calendar} alt="calendar" />
                    <MobileItemValue>
                      {isCompletedSurveys ? completedDate : timeLeft}
                    </MobileItemValue>
                  </StackItem>
                </MobileItemValue>
              </tr>
              <tr>
                <MobileItemDescription>
                  {t(keys.points)} :
                </MobileItemDescription>
                <MobileItemValue>
                  <PointsItem>{appendPoints(points)}</PointsItem>
                </MobileItemValue>
              </tr>
              {!isCompletedSurveys && (
                <tr>
                  <MobileItemDescription>
                    {t(keys.survey_length)} :
                  </MobileItemDescription>
                  <MobileItemValue>{timeToComplete}</MobileItemValue>
                </tr>
              )}
              {isCompletedSurveys && (
                <tr>
                  <MobileItemDescription>
                    {t(keys.status)} :
                  </MobileItemDescription>
                  <MobileItemValue>{status}</MobileItemValue>
                </tr>
              )}
            </table>
          </SurveyItemWrapper>
        </CardActionArea>
      </Card>
    );
  }

  if (isCompletedSurveys) {
    return (
      <SurveyItemWrapper>
        <ItemText>{surveyNumber}</ItemText>
        <PointsItemWrapper>
          <PointsItem>{appendPoints(points)}</PointsItem>
        </PointsItemWrapper>
        <StackItem direction="row" alignItems="center" gap={1}>
          <img src={calendar} alt="calendar" />
          <ItemText>{completedDate}</ItemText>
        </StackItem>
        <ItemText>{status}</ItemText>
      </SurveyItemWrapper>
    );
  }

  return (
    <SurveyItemWrapper>
      <ItemText>{timeToComplete}</ItemText>
      <PointsItemWrapper>
        <PointsItem>{appendPoints(points)}</PointsItem>
      </PointsItemWrapper>
      <StackItem direction="row" alignItems="center" gap={1}>
        <img src={calendar} alt="calendar" />
        <ItemText>{timeLeft}</ItemText>
      </StackItem>
      <ItemText>{surveyNumber}</ItemText>
      <StartWrapper>
        <StartButton variant="outlined" onClick={() => onStartClicked()}>
          {t(keys.start_survey)}
        </StartButton>
      </StartWrapper>
    </SurveyItemWrapper>
  );
};

export default SurveyItem;
