import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import { State } from "data/reducers/state";
import { CoinsHistoryActions } from "data/slices/coinshistory";
import { getCoinsHistoryFilterApi } from "data/slices/coinshistory/api";
import {
  CoinsHistoryFilterResponse,
  CoinsHistoryStateProps,
} from "data/slices/coinshistory/interface";
import { WalletDetailsActions } from "data/slices/wallet";
import { getAchievementTierApi } from "data/slices/wallet/api";
import {
  AchievementTierDetailsResponse,
  WalletDetailsStateProps,
} from "data/slices/wallet/interface";
import keys from "locale/keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  COINS_HISTORY_EARNINGS,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  POINTS_FILTER_BY,
} from "utils/constants";
import {} from "utils/mappers/MyOrdersMapper";
import {
  getAchievementTierDetails,
  getChartData,
  getWalletEarningLogs,
} from "utils/mappers/WalletEarningsLogMapper";
import MyWallet from "widgets/dashboard/wallet";
import EarningsLog from "widgets/dashboard/wallet/EarningsLog";

import { Wrapper } from "../styled.components";

const MyWalletContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEarningsLog, setShowEarningsLog] = useState(false);
  const [isLoadingGraphData, setIsLoadingGraphData] = useState(false);
  const [isLoadingAchievementData, setIsLoadingAchievementData] =
    useState(false);
  const [graphData, setGraphData] = useState([]);
  const [achievementTierData, setAchievementTierData] = useState([]);

  const { isFetching, walletdetails } = useSelector<
    State,
    WalletDetailsStateProps
  >((state) => state.walletdetails);

  useEffect(() => {
    dispatch(WalletDetailsActions.request());
  }, [dispatch]);

  const {
    isFetching: fetchingCoinsHistory,
    coinshistory,
    isError,
  } = useSelector<State, CoinsHistoryStateProps>((state) => state.coinshistory);

  const getCoinsHistory = (page: number, pageSize: number) => {
    dispatch(
      CoinsHistoryActions.request({
        status: COINS_HISTORY_EARNINGS,
        page,
        pageSize,
      }),
    );
  };

  const getGraphsData = async (status: string, filterType: string) => {
    setIsLoadingGraphData(true);
    const response: CoinsHistoryFilterResponse = await getCoinsHistoryFilterApi(
      {
        status: status,
        filterBy: filterType,
      },
    );

    setIsLoadingGraphData(false);
    setGraphData(getChartData(response, filterType));
  };

  const getAchievementTierData = async () => {
    setIsLoadingAchievementData(true);
    const response: AchievementTierDetailsResponse =
      await getAchievementTierApi();

    setIsLoadingAchievementData(false);
    setAchievementTierData(getAchievementTierDetails(response?.response));
  };

  useEffect(() => {
    getAchievementTierData();
    getGraphsData(COINS_HISTORY_EARNINGS, POINTS_FILTER_BY.WEEK);
  }, [dispatch]);

  const onShowEarningsLogHandler = () => {
    setShowEarningsLog(true);
    getCoinsHistory(DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE);
  };

  const onEarningsBackClicked = () => {
    setShowEarningsLog(false);
  };

  const updateGraphsData = (filterType: string) => {
    getGraphsData(COINS_HISTORY_EARNINGS, filterType);
  };

  if (showEarningsLog) {
    const myEarnings = getWalletEarningLogs(coinshistory.rows);
    return (
      <>
        <HeaderWithFilter
          title={t(keys.earnings_log)}
          showFilter={false}
          showBackButton={true}
          onBackButtonClickHandler={onEarningsBackClicked}
        />
        <Divider />
        <Wrapper>
          <EarningsLog
            earningsToDisplay={myEarnings}
            recordsCount={coinshistory.count}
            isLoading={fetchingCoinsHistory}
            isError={isError}
            fetchCoinsHistory={getCoinsHistory}
          />
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <HeaderWithFilter title={t(keys.my_wallet)} showFilter={false} />
      <Divider />
      <Wrapper>
        <MyWallet
          isFetching={isFetching}
          walletDetails={walletdetails}
          onShowEarningsLogHandler={onShowEarningsLogHandler}
          completedSurveys={walletdetails?.survaysCompleted}
          chartData={graphData}
          isGraphsLoading={isLoadingGraphData}
          isAchievementsLoading={isLoadingAchievementData}
          achievementTierDetails={achievementTierData}
          updateGraphsData={updateGraphsData}
        />
      </Wrapper>
    </>
  );
};

export default MyWalletContainer;
