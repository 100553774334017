import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import { CoinsHistoryFilterRequest, CoinsHistoryRequest } from "./interface";

export const getCoinsHistoryApi = (payload: CoinsHistoryRequest) => {
  const url = `${getBaseURL()}${ENDPOINTS.COINS_HISTORY}?status=${payload.status}&page_size=${payload.pageSize}&page=${payload.page}`;
  return axios.get(url, {
    headers: getHeaders(),
  });
};

export const getCoinsHistoryFilterApi = async (
  payload: CoinsHistoryFilterRequest,
) => {
  try {
    const url = `${getBaseURL()}${ENDPOINTS.COINS_HISTORY}?status=${payload.status}&filterBy=${payload.filterBy}`;
    const { data } = await axios.get(url, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};
