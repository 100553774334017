import withLoader from "hoc/loader";
import { isEqual } from "lodash";
import { useState } from "react";
import { useLocation } from "react-router";

import welcomeBackgroundImg from "../../assets/icons/welcomeBackgroundImg.png";
import { WELCOME_STEPS_COUNT } from "./Constants";
import WelcomeHeader from "./Header";
import { UserDetailsProps, WelcomeProps } from "./interface";
import Questionnaire from "./Questionnaire";
import { WelcomeBG, Wrapper } from "./styled.components";

const WelcomePage = (props: WelcomeProps) => {
  const { state } = useLocation();
  const { navigateToDashboard, userName, walletBalance } = props;
  const fromdashboard = state?.fromdashboard;
  const [step, setStep] = useState(fromdashboard ? 1 : 0);

  const onNextHandler = (details?: UserDetailsProps) => {
    setStep((prev) => {
      return prev === WELCOME_STEPS_COUNT ? step : step + 1;
    });
    if (step === WELCOME_STEPS_COUNT) {
      navigateToDashboard(details);
    }
  };

  const onBackHandler = () => {
    setStep((prev) => {
      return prev === 0 ? step : step - 1;
    });
  };

  return (
    <div style={{ position: "relative" }}>
      {isEqual(step, 0) && (
        <WelcomeBG src={welcomeBackgroundImg} alt="welcome_bg" />
      )}
      <Wrapper data-testid="welcome-wrapper">
        <WelcomeHeader
          step={step}
          userName={userName}
          walletBalance={walletBalance}
        />
        <Questionnaire
          step={step}
          onNextHandler={onNextHandler}
          onBackHandler={onBackHandler}
          navigateToDashboard={navigateToDashboard}
        />
      </Wrapper>
    </div>
  );
};

const PURE = withLoader(WelcomePage);
export default PURE;
