import { BaseStateProps } from "data/interface";

export interface CreateSupportTicketRequest {
  category: string;
  description: string;
  imageURL?: string;
}

export interface UpdateSupportTicketRequest {
  id: number;
  category: string;
  description: string;
  imageURL?: string;
  status: string;
}

export interface UpdateSupportTicketResponse {
  message: string;
  error: any;
}

export interface CreateSupportTicketResponse {
  message: string;
  response: TicketList;
  error: any;
}

export interface TicketList {
  rows: TicketDetailsProps[];
}

export interface SupportTicketsStateProps extends BaseStateProps {
  tickets: TicketDetailsProps[];
  isError: boolean;
}

export interface TicketDetailsProps {
  id: number;
  category: string;
  description: string;
  date: string;
  status: string;
  imageURL: string;
  createdAt: string;
}

export const initialState: SupportTicketsStateProps = {
  isError: false,
  isFetching: false,
  tickets: [],
};
