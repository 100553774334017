import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState, SurveyListRequest } from "./interface";

export const SurveyListSlice = createSlice({
  name: "surveylist",
  initialState: initialState,
  reducers: {
    request: (state, action: PayloadAction<SurveyListRequest>) => {
      state.isFetchingNewSurveys = true;
      state.isFetchingCompletedSurveys = true;
      state.isError = false;
    },
    updateNewSurveys: (state, action) => {
      state.newsurveys = action.payload;
      state.isFetchingNewSurveys = false;
    },
    updateCompletedSurveys: (state, action) => {
      state.completedsurveys = action.payload;
      state.isFetchingCompletedSurveys = false;
    },
    error: (state, action) => {
      state.isFetchingNewSurveys = false;
      state.isFetchingCompletedSurveys = false;
      state.isError = true;
    },
  },
});

export const { actions: SurveyListActions } = SurveyListSlice;

export default SurveyListSlice.reducer;
