import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { css, IconButton, List, Toolbar, Typography } from "@mui/material";
import { mobile, tablet } from "commons/styled.components";
import TextField from "components/textfield";
import WalletBalance from "components/walletbalance";
import { COLORS } from "utils/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  padding-right: 10px;

  ${mobile(css`
    padding: 10px;
  `)}
`;

export const SideNavWrapper = styled.div`
  ${tablet(css`
    display: none;
  `)}
`;

export const CustomToolbar = styled(Toolbar)`
  padding: 0;
`;

export const DrawerExpand = styled.img`
  ${tablet(css`
    display: none;
  `)}
`;

export const DrawerContent = styled.div`
  height: 100%;
  background: ${COLORS.BRAND_LIGHT_BACKGROUND};
  display: flex;
  flex-direction: column;
`;

export const SearchField = styled(TextField)`
  height: 42px;
  border-radius: 4px;
  color: #afafaf;
`;

export const Wallet = styled(WalletBalance)`
  margin-left: auto;
`;

export const FooterList = styled(List)`
  margin-top: auto;
  margin-bottom: 16px;
`;

export const MenuIconWrapper = styled.div`
  display: none;
  position: relative;
  ${tablet(css`
    display: flex;
    align-items: center;
  `)}
`;

export const ListContent = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0px 0px 14px 12px #8888880d;
  width: max-content;
  right: 0;
  top: 0;
`;

export const MenuWrapper = styled(MenuIcon)`
  position: relative;
`;
export const IconButtonWrapper = styled(IconButton)`
  ${tablet(css`
    display: none;
  `)}
`;

export const LogoWrapper = styled.img`
  display: none;
  width: 12rem;
  height: 2.5rem;
  ${tablet(css`
    display: flex;
  `)}
`;

export const IconWrapper = styled.div`
  ${tablet(css`
    display: none;
  `)}
`;

export const UserRelatedDetails = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ItemImage = styled.img`
  &.ItemColor {
    filter: invert(1) sepia(1) saturate(0) brightness(1.5) contrast(1);
  }
`;

export const NotificationCountlabel = styled(Typography)`
  background: white;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 6px;
  border-radius: 4px;
`;
