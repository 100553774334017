import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import {
  AddCoupounToCartRequest,
  DeleteCartItemRequest,
  UpdateItemQuantityRequest,
} from "./interface";

export const addCouponToCartApi = async (payload: AddCoupounToCartRequest) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.CART_ADDITEM,
      payload,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};

export const deleteCartItemApi = async (payload: DeleteCartItemRequest) => {
  try {
    const url = getBaseURL() + ENDPOINTS.CART_DELETE_ITEM + payload.id;
    const { data } = await axios.delete(url, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for erro snackbar validation
  }
};

export const purchaseCartApi = async () => {
  try {
    const { data } = await axios.get(getBaseURL() + ENDPOINTS.CART_PURCHASE, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for erro snackbar validation
  }
};

export const updateQuantityApi = async (payload: UpdateItemQuantityRequest) => {
  try {
    const url = getBaseURL() + ENDPOINTS.CART_QUANTITY + payload.id;
    const { data } = await axios.patch(
      url,
      { quantity: payload.quanity },
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
