import styled from "@emotion/styled";
import {
  Button,
  Card,
  css,
  IconButton,
  Select,
  Typography,
} from "@mui/material";
import { mobile } from "commons/styled.components";

export const CouponCard = styled(Card)`
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #e4e4e4;
`;

export const CardContent = styled.div`
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
`;

export const CouponImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 10rem;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.08);
`;

export const CouponName = styled(Typography)`
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CouponValueWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  align-self: stretch;
  height: 40px;
`;

export const ValueText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
`;

export const ValueSelect = styled(Select)`
  border-color: black;
  flex-grow: 1;
  flex-basis: 0;
`;

export const ValidityText = styled(Typography)`
  color: #727272;
  font-size: 12px;
  font-weight: 400;
`;

export const AddtoCartButton = styled(Button)`
  color: black;
  border-color: black;
  padding: 2px 12px;
  text-transform: none;
`;

export const ModalContent = styled.div`
  position: relative;
  overflowy: auto;
  background-color: #fff;
  padding: 24px;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;

export const ModalCloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  ${mobile(css`
    flex-direction: column;
  `)}
`;

export const ModalLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 0.4;
  flex-basis: 0;
  padding-top: 64px;

  ${mobile(css`
    padding-top: 32px;
  `)}
`;

export const ModalRightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.6;
  flex-basis: 0;
`;

export const ModalRightText = styled(Typography)<{
  showmargin?: boolean;
  istitle?: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  margin-top: ${(props) => (props.showmargin ? "16px" : 0)};
  color: ${(props) => (props.istitle ? "#000" : "#727272")};
`;

export const StockInfo = styled.span<{ instock?: boolean }>`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  background: ${(props) => (props.instock ? "#EDF2FE" : "#f7eded")};
`;

export const StockText = styled(Typography)<{ instock?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.instock ? "#4976F4" : "#AF4B4B")};
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
`;

export const ShowMoreText = styled(Button)`
  font-size: 12px;
  font-weight: 500;
`;
