import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import keys from "locale/keys";
import { useTranslation } from "react-i18next";
import { MAX_SURVEYS_IN_DASHBOARD } from "utils/constants";

import { SurveySetProps } from "./interface";
import {
  Indicator,
  SetHeaderWrapper,
  SetStatus,
  SetTile,
  SurveysTableWrapper,
  TableContentWrapper,
  TasksWrapper,
  ViewMoreText,
  ViewMoreWrapper,
  Wrapper,
} from "./styled.components";
import TaskHeader from "./SurveyHeader";
import TaskItem from "./TaskItem";

const SurveySet = (props: SurveySetProps) => {
  const {
    title,
    isCompletedSurveys,
    statusText,
    headerItems,
    surveyItems,
    setTopMargin,
    onViewMoreClicked,
    isLoading = false,
    showAllSurveys = false,
    onStartSurveyClicked,
    isError = false,
    isMobile = false,
    totalCount,
  } = props;
  const { t } = useTranslation();

  //show view more only if there more than MAX_SURVEYS_IN_DASHBOARD. When clicked on view more show the pending surveys
  const hasMoreThanAllowedSurveys =
    !showAllSurveys && totalCount > MAX_SURVEYS_IN_DASHBOARD;
  var surveysToDisplay = surveyItems;
  if (hasMoreThanAllowedSurveys) {
    surveysToDisplay = surveysToDisplay.slice(0, MAX_SURVEYS_IN_DASHBOARD);
  }

  const showViewMoreLink = () => {
    return (
      <ViewMoreWrapper onClick={() => onViewMoreClicked(isCompletedSurveys)}>
        <ViewMoreText>{t(keys.view_more)}</ViewMoreText>
        <ArrowForwardIcon style={{ fontSize: "0.88rem" }} />
      </ViewMoreWrapper>
    );
  };

  const showSurveysList = () => {
    return surveysToDisplay.map((item, index) => {
      return (
        <TaskItem
          key={index}
          {...item}
          isCompletedSurveys={isCompletedSurveys}
          onStartSurveyClicked={onStartSurveyClicked}
          isMobile={isMobile}
        ></TaskItem>
      );
    });
  };

  const showlist = () => {
    const isEmpty = surveysToDisplay && surveysToDisplay.length === 0;
    if (isLoading || isError || isEmpty) {
      return (
        <LoadingEmptyError
          isEmpty={isEmpty}
          isLoading={isLoading}
          isError={isError}
        />
      );
    }

    if (isMobile) {
      return (
        <>
          {surveysToDisplay && showSurveysList()}
          {hasMoreThanAllowedSurveys && showViewMoreLink()}
        </>
      );
    }

    return (
      <>
        <SurveysTableWrapper>
          <TableContentWrapper>
            <TasksWrapper>
              {/* display headers */}
              <TaskHeader
                headerItems={headerItems}
                isCompletedSurveys={isCompletedSurveys}
              />
              {/* display items */}
              {surveysToDisplay && showSurveysList()}
            </TasksWrapper>
          </TableContentWrapper>
        </SurveysTableWrapper>
        {hasMoreThanAllowedSurveys && showViewMoreLink()}
      </>
    );
  };

  return (
    <Wrapper topMargin={setTopMargin ? "32px" : "0px"}>
      <SetHeaderWrapper>
        <Indicator color={isCompletedSurveys ? "#589E67" : "#4976F4"} />
        <SetTile>{title}</SetTile>
        {!isLoading && <SetStatus>{statusText}</SetStatus>}
      </SetHeaderWrapper>
      {showlist()}
    </Wrapper>
  );
};

export default SurveySet;
