export const ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgotpassword",
  CHANGE_PASSWORD: "/resetPassword/:userid/:token",
  SIGNUP: "/signup/:id",
  WELCOME: "/welcome",
  DASHBOARD: "/dashboard/*",

  //survey redirects
  SURVEY_REDIRECT: "/survey-redirect",
  SURVEY_REDIRECT_OVERQUOTA: "/survey-failure-overquota",
  SURVEY_REDIRECT_SECURITY: "/survey-security-term",
  SURVEY_REDIRECT_TERMINATED: "/survey-failure-terminated",
};

export const PRELOGIN_INDEX = {
  LOGIN: 0,
  FORGOT_PASSWORD: 1,
  CHANGE_PASSWORD: 2,
  SIGNUP: 3,
};

export const EMAIL_REGEX = /^[\w-\\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const GENDERS = ["Male", "Female", "Others"];

export const ETHNICITY = [
  "Asian",
  "Black or African American",
  "Hispanic or Latino",
  "Native American or Alaska Native",
  "Native Hawaiian or Other Pacific Islander",
  "White",
  "Middle Eastern or North African",
  "Multiracial or Biracial",
  "Other",
  "Prefer not to say",
];

export const MARRIAGE_STATUS = [
  "Single",
  "Married",
  "Divorced",
  "Widowed",
  "Separated",
  "Domestic Partnership",
];

export const SUPPORT_CATEGORIES = ["My Orders", "My Earnings"];

export const DASHBOARD_ROUTES = {
  TASKS: "surveys",
  MY_WALLET: "mywallet",
  MY_ORDERS: "orders",
  REDEEM: "redeem",
  CART: "cart",
  REFER_USERS: "referal",
  NOTIFICATIONS: "notifications",
  SUPPORT: "support",
  PROFILE_SETTINGS: "profile",
  LOGOUT: "logout",
};

export const DASHBOARD_DEFAULT_ROUTE = "/dashboard/" + DASHBOARD_ROUTES.TASKS;

export const SNACKBAR_AUTO_DISMISS_DURATION_MS = 5000;

export const TOTAL_STAR_USER_LEVELS = 8;
export const MAX_SURVEYS_IN_DASHBOARD = 10;
export const DEFAULT_TERMS_CHARS_TO_SHOW = 150;

export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_ROW_OPTIONS_PER_PAGE = [10, 25, 50, 100];

export const DEFAULT_REDEEM_ROWS_PER_PAGE = 24;
export const REDEEM_ROW_OPTIONS_PER_PAGE = [24, 48];

export const STORAGE_TOKEN = "token";
export const STORAGE_REFRESH_TOKEN = "refreshToken";
export const STORAGE_IS_FIRST_LOGIN = "isFirstLogin";
export const STORAGE_SELECTED_MENU = "selected_menu";
export const STORAGE_USERID = "userid";
export const STORAGE_USER_ID = "user_id";

export const SURVEY_STATUS_COMPLETED = "Completed";
export const SURVEY_STATUS_ASSIGNED = "Assigned";

export const DOB_FORMAT = "DD/MM/YYYY";
export const SURVEY_DATE_FORMAT = "dd/MM/yyyy";
export const EARNINGS_DATE_FORMAT = "dd/MM/yyyy";
export const DEFAULT_PLACEAHOLDER = "-";

export const MENU_ITEM_PROFILE_SETTINGS = 0;
export const MENU_ITEM_LOGOUT = 1;

export const TABLE_TYPE_WALLET_EARNINGS = 0;
export const TABLE_TYPE_ORDERS = 1;
export const TABLE_TYPE_SUPPORT_LIST = 2;
export const TABLE_TYPE_RETAILERS = 3;
export const TABLE_TYPE_REFERALS = 4;

export const PROFILE_DOB_MIN_AGE = 18;

//to display in UI
export const POINTS_FILTER = {
  WEEK: "Last 7 Days",
  MONTH: "Last 30 Days",
  QUARTER: "Last 90 Days",
};

//to send to api
export const POINTS_FILTER_BY = {
  WEEK: "lastSevenDays",
  MONTH: "last30Days",
  QUARTER: "last90Days",
};

export const STATUS_TEST = {
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed",
  RESOLVED: "Resolved",
  OPEN: "Open",
};

export const STATUS_TYPE = {
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
  OPEN: 3,
};

export const COINS_HISTORY_REDEEMED = "Redeemed";
export const COINS_HISTORY_EARNINGS = "Earned";

export const EARNING_TYPE_PROFILE = "Profile";

export const SUPPORT_DESCRIPTION_MAX_LEGTH = 50;

export const PROFILE_SETTINGS_FIELD = {
  FIRST_NAME: 0,
  LAST_NAME: 1,
  ETHNICITY: 2,
  POSTCODE: 3,
  MOBILE: 4,
  DOB: 5,
  EMAIL: 6,
  ADDRESS: 7,
  GENDER: 8,
  MARITAL: 9,
};

export const TICKET_TYPE = {
  OPEN: "Open",
  RESOLVED: "Resolved",
};

export const SNACKBAR_TYPE = {
  ERROR: 0,
  SUCCESS: 1,
};

export const MEDIA_SIZES = {
  mobile: "576px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
  mediumMobile: "425px",
  smallMobile: "320px",
};

export const PROFILE_SETTINGS_TYPE_TEXT = 0;
export const PROFILE_SETTINGS_TYPE_GENDER = 1;
export const PROFILE_SETTINGS_TYPE_MARITAL = 2;
export const PROFILE_SETTINGS_TYPE_DOB = 3;
export const PROFILE_SETTINGS_TYPE_ETHNICITY = 4;
export const PROFILE_SETTINGS_TYPE_MOBILE = 5;
export const PROFILE_SETTINGS_TYPE_ADDRESS = 6;

export const USER_INVITATION_MASTER_CODE = 2;

export const COUPONS_PER_ROW = 4;
export const COUPON_ROWS_DEFAULT = 2;
export const COUPON_ROWS_AFTER_SHOW_MORE = 4;

export const ALLOWED_COUNTRY_LIST = ["UK", "GB", "IN"];

export const SURVEY_STATUS = {
  COMPLETED: "Completed",
  FAILED: "Failed",
};
