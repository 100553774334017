import { Search } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IconButton, InputAdornment, Tab, Tabs } from "@mui/material";
import keys from "locale/keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";

import EditIcon from "../../assets/icons/Edit.svg";
import { HeaderWithFilterProps } from "./interface";
import { FilterButton, SearchInput, Title, Wrapper } from "./styled.components";

const HeaderWithFilter = (props: HeaderWithFilterProps) => {
  const {
    title,
    showFilter,
    showBackButton,
    onBackButtonClickHandler,
    onOptionButtonClicked,
    showEdit,
    tabs,
    selectedTab,
    onTabChanged,
    showSearchField,
    onSearchInput,
  } = props;
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => onSearchInput?.(searchInput), 500);
    return () => clearTimeout(timeOutId);
  }, [onSearchInput, searchInput]);

  const onBackButtonClicked = () => {
    onBackButtonClickHandler?.();
  };

  //TODO: set type to differentiate
  const onOptionClicked = () => {
    onOptionButtonClicked?.();
  };

  return (
    <Wrapper showbackbutton={showBackButton}>
      {showBackButton && (
        <IconButton
          style={{ color: "#000000" }}
          onClick={() => onBackButtonClicked()}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}
      <Title>{title}</Title>
      {tabs && (
        <Tabs
          value={selectedTab}
          onChange={onTabChanged}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: COLORS.BRAND_DEFAULT_COLOR,
            },
          }}
        >
          {tabs.map((item) => {
            return (
              <Tab
                label={item.title}
                icon={<img src={item.icon} alt="tab-icon" />}
                iconPosition="start"
                key={item.title}
                style={{ color: "black", textTransform: "none" }}
              />
            );
          })}
        </Tabs>
      )}
      {showFilter && (
        <FilterButton
          variant="outlined"
          onClick={() => onOptionClicked()}
          startIcon={<FilterAltOutlinedIcon />}
        >
          {t(keys.filter)}
        </FilterButton>
      )}
      {showSearchField && (
        <SearchInput
          id="input-with-icon-textfield"
          size="small"
          autoComplete="off"
          onInput={(e: any) => {
            setSearchInput(e.target.value);
          }}
          InputProps={{
            placeholder: "Search",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
      {showEdit && (
        <FilterButton
          variant="outlined"
          onClick={() => onOptionClicked()}
          startIcon={<img src={EditIcon} alt="edit-icon" />}
        >
          {t(keys.edit)}
        </FilterButton>
      )}
    </Wrapper>
  );
};

export default HeaderWithFilter;
