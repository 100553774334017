import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { WalletDetailsActions } from ".";
import { walletDetailsApi } from "./api";

export default function* walletDetails(): SagaIterator {
  try {
    const walletDetailsResponse = yield call(walletDetailsApi);
    const { data } = walletDetailsResponse;

    yield put(WalletDetailsActions.success(data?.response));
  } catch (e) {
    yield put(WalletDetailsActions.error(e?.response?.data?.message));
  }
}
