import { Link } from "@mui/material";
import * as React from "react";
import { COLORS } from "utils/colors";

const CustomLink = (props: any) => {
  return (
    <Link
      onClick={props.onClickHandler}
      {...props}
      underline="hover"
      color={COLORS.BRAND_DEFAULT_COLOR}
      variant="subtitle2"
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </Link>
  );
};

export default CustomLink;
