import i18n from "i18n";
import keys from "locale/keys";
import { isEmpty } from "lodash";
import { isValidAge } from "utils/Utils";

import { ValidationModel } from "./interface";

export const ValidateWelcomeProfileDetails = (dob: string): ValidationModel => {
  const validationModel: ValidationModel = {
    isValidDOB: true,
    DOBMessage: "",
  };

  if (isEmpty(dob)) {
    validationModel.isValidDOB = false;
    validationModel.DOBMessage = i18n.t(keys.please_select_DOB);
  } else if (!isValidAge(new Date(dob))) {
    validationModel.isValidDOB = false;
    validationModel.DOBMessage = i18n.t(keys.please_select_valid_date);
  }

  return validationModel;
};
