import { SurveyDetails } from "data/slices/surveylist/interface";
import i18n from "i18n";
import keys from "locale/keys";
import { SURVEY_DATE_FORMAT } from "utils/constants";
import { formatDate, getStringWithDefaultValue } from "utils/Utils";
import {
  SurveyItemProps,
  SurveySetProps,
} from "widgets/dashboard/surveys/interface";

//TODO change to generic table
export const surveysMapper = (
  list: SurveyDetails[],
  isCompletedSurveys: boolean = false,
  isError: boolean = false,
  totalCount: number = 0,
): SurveySetProps => {
  const hasSurveys = !isError && list && list.length > 0;

  var mappedItems: SurveyItemProps[] = [];
  if (hasSurveys) {
    list.forEach((item) => {
      mappedItems.push({
        surveyName: item.MasterSurvay.surveyName,
        surveyNumber: item.MasterSurvay.surveyNumber,
        category: getStringWithDefaultValue(item.MasterSurvay.category),
        timeLeft: formatDate(item.MasterSurvay.endDate, SURVEY_DATE_FORMAT),
        timeToComplete: `${item.MasterSurvay.lengthOfInterview} mins`,
        points: item.MasterSurvay.reward,
        isCompletedSurveys,
        completedDate: formatDate(item.updatedAt, SURVEY_DATE_FORMAT),
        url: item.MasterSurvay.url,
        providerKey: item.MasterSurvay.provider.appKey,
        id: item.id,
        status: getStringWithDefaultValue(item.status),
      });
    });
  }

  return {
    title: isCompletedSurveys
      ? i18n.t(keys.completed_surveys)
      : i18n.t(keys.new_surveys),
    isCompletedSurveys: isCompletedSurveys,
    statusText: getStatus(hasSurveys, totalCount, isCompletedSurveys),
    headerItems: getHeaders(isCompletedSurveys),
    surveyItems: mappedItems,
    totalCount: totalCount,
  };
};

const getStatus = (
  hasSurveys: boolean,
  length: number,
  isCompletedSurveys: boolean,
) => {
  if (!hasSurveys) {
    return "";
  }
  if (isCompletedSurveys) {
    return `${length} ${i18n.t(keys.completed)}`;
  } else {
    return `${length} ${i18n.t(keys.to_complete)}`;
  }
};

//TODO move to strings file
const getHeaders = (isCompletedSurveys: boolean) => {
  if (isCompletedSurveys) {
    return [
      i18n.t(keys.survey_number),
      i18n.t(keys.points_earned),
      i18n.t(keys.completed_date),
      i18n.t(keys.status),
    ];
  } else {
    //last empty test is required, dont remove. To show place holder for start button
    return [
      i18n.t(keys.survey_length),
      i18n.t(keys.points),
      i18n.t(keys.end_date),
      i18n.t(keys.survey_number),
      "",
    ];
  }
};
