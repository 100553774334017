import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { UserDetailsActions } from ".";
import { userDetailsApi } from "./api";

export default function* userDetails(): SagaIterator {
  try {
    const userDetailsResponse = yield call(userDetailsApi);
    const { data } = userDetailsResponse;

    yield put(UserDetailsActions.success(data?.response));
  } catch (e) {
    yield put(UserDetailsActions.error(e?.response?.data?.message));
  }
}
