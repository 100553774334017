import styled from "@emotion/styled";
import {
  Card,
  css,
  FormControlLabel,
  keyframes,
  RadioGroup,
  Typography,
} from "@mui/material";
import { laptop, mobile, smallMobile, tablet } from "commons/styled.components";
import CustomLink from "components/link";
import TextField from "components/textfield";
import { COLORS } from "utils/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const WelcomeBG = styled.img`
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: space-between;
  gap: 1rem;
  ${tablet(css`
    flex-direction: column;
  `)}
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0.25rem;
  align-items: center;
  padding: 0 1rem;
`;

export const Step = styled.div<{
  isSelected: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
}>`
  height: 10px;
  flex-grow: 1;
  border-bottom-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-bottom-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  border-top-left-radius: ${(props) => (props.isFirstStep ? "4px" : "0px")};
  border-top-right-radius: ${(props) => (props.isLastStep ? "4px" : "0px")};
  min-width: 4rem;
  background: ${(props) =>
    props.isSelected
      ? COLORS.BRAND_DEFAULT_COLOR
      : COLORS.BRAND_LIGHT_BACKGROUND};
`;

export const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
`;

export const WelcomeWrapper = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: -17%;
  text-align: center;
  ${laptop(css`
    padding: 2rem;
    justify-content: flex-start;
  `)}
  ${tablet(css`
    margin-top: 0%;
    width: 100%;
  `)}
  ${mobile(css`
    margin-top: 0%;
    width: 100%;
  `)}
`;

export const WelcomeTitle = styled(Typography)`
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
`;

export const WelcomeMessage = styled(Typography)`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
`;

export const WelcomeCard = styled(Card)`
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  border-radius: 4px
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

export const FilloutLabel = styled(Typography)`
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  flex-grow: 1;
  align-content: center;
`;

export const InThreeMinLabel = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
`;

export const ToDashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const ToDashboardLink = styled(CustomLink)`
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  color: #0000b3 !important;
`;

export const ImageWrapper = styled.img`
  width: 12rem;
  height: 10rem;
`;

export const rotate = keyframes`
0%   { transform: rotate(0deg);}
50%  {transform: rotate(-180deg);}
100% { transform: rotate(-360deg);}
`;

export const RotatingImage = styled.img`
  width: 3rem;
  height: 3rem;
  animation-name: ${rotate};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export const GenderImages = styled.div`
  display: flex;
  justify-content: center;
`;

export const QuestionnaireTitle = styled(Typography)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
`;

export const GroupWrapper = styled(RadioGroup)`
  width: 40%;
  gap: 2.5rem;
  align-items: center;
  margin-top: 5rem;
  &.maritalStatus {
    ${tablet(css`
      width: 100%;
    `)}
    ${mobile(css`
      margin-top: 2rem !important;
      width: 100%;
    `)}
${smallMobile(css`
      margin-top: 0 !important;
    `)}
  }
  ${mobile(css`
    width: 100%;
  `)};
`;

export const DateInputWrapper = styled.div`
  div.MuiInputBase-root {
    height: 2.8rem;
    &.MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: black;
      }
    }
  }
`;

export const AddressImageWrapper = styled.div`
  display: flex;

  &.MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: black;
    }
  }
`;

export const Img1Animation = keyframes`
  0%   { left:34px; top:0px;}  
  100% {left:80px; top: 0px;}
`;

export const Img2Animation = keyframes`
 0%   { right:34px; top:0px;}
100%   { right:80px; top:0px;}
 
`;

export const AddressImage = styled.img`
  &.img1 {
    position: relative;
    animation-name: ${Img1Animation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  &.img2 {
    position: relative;
    animation-name: ${Img2Animation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;

export const RadioGroupWrapper = styled(RadioGroup)`
  gap: 1rem;
  justify-content: center;
  &.gender {
    ${smallMobile(css`
      display: flex;
      flex-direction: column;
    `)}
  }

  &.relationship {
    ${mobile(css`
      margin-left: 1.4rem;
    `)}
  }
`;

export const AddressField = styled(TextField)`
  width: 90%;

  &.MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: black;
    }
  }
`;

export const OutlinedFormControl = styled(FormControlLabel)`
  border: 0.06rem solid #727272;
  border-radius: 0.25rem;
  padding: 0 1.12rem 0 0.38rem;
  font-size: 0.88rem;

  .Mui-checked.MuiButtonBase-root {
    color: #000000;
  }
`;

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 4rem;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding-top: 2rem;
`;

export const DOBWrapper = styled.div`
display: flex;
flex - direction: row;
gap: 2rem;
`;

export const ProfileWrapper = styled.div`
  display: flex;
`;

export const LogoImageWrapper = styled.img`
  ${smallMobile(css`
    width: 140px;
  `)}
`;

export const ImageContainer = styled.div`
  padding: 0 1.75rem;
`;
export const ImageContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
