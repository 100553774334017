import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import { surveyUpdate } from "data/slices/surveylist/api";
import keys from "locale/keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { STORAGE_USER_ID } from "utils/constants";
import { getFromLocalStorage } from "utils/Utils";

import AppLogo from "../../assets/icons/ApplogoBlack.svg";
import { AppLogoImg, Message, Wrapper } from "./styled.components";
import { getRequestPayload, isSurveySucccess } from "./utils";

const SurveyCompletionRedirection = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const isSuccessRedirection = isSurveySucccess(params);

  useEffect(() => {
    submitSurveyStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSurveyStatus = async () => {
    setIsLoading(true);

    const userId = getFromLocalStorage(STORAGE_USER_ID);
    const request = getRequestPayload(userId, params);
    if (request) {
      await surveyUpdate(request);
    }

    setIsLoading(false);
  };

  const getStatusIcon = () => {
    return isSuccessRedirection ? (
      <CheckCircleOutlineOutlinedIcon
        style={{ color: "green", width: "4rem", height: "4rem" }}
      />
    ) : (
      <CancelOutlinedIcon
        style={{ color: "red", width: "4rem", height: "4rem" }}
      />
    );
  };

  return (
    <Wrapper>
      <AppLogoImg src={AppLogo} alt="app logo" />
      {getStatusIcon()}
      <Message>
        {isSuccessRedirection
          ? t(keys.survey_success_msg)
          : t(keys.survey_failure_msg)}
      </Message>
      {isLoading && (
        <div>
          <LoadingEmptyError isLoading={true} isError={false} isEmpty={false} />
        </div>
      )}
    </Wrapper>
  );
};

export default SurveyCompletionRedirection;
