export interface SurveyListRequest {
  status: string;
  pageSize: number;
  page: number;
}

export interface SurveyListResponse {
  message: string;
  response: SurveyListRowsResponse;
}

export interface SurveyListRowsResponse {
  count: number;
  rows: SurveyDetails[];
}

export interface SurveyDetails {
  id: number;
  status: string;
  completedDate?: string;
  updatedAt?: string;
  MasterSurvay: MasterSurveyDetails;
}

interface MasterSurveyDetails {
  surveyNumber: number;
  surveyName: string;
  lengthOfInterview: number;
  category: string;
  url: string;
  reward: number;
  endDate: string;
  provider: {
    appKey: string;
  };
  status: string;
}

export interface SurveyListStateProps {
  isFetchingNewSurveys: boolean;
  isFetchingCompletedSurveys: boolean;
  newsurveys: SurveyListRowsResponse;
  completedsurveys: SurveyListRowsResponse;
  isError: boolean;
}

export const initialState: SurveyListStateProps = {
  isFetchingNewSurveys: false,
  isFetchingCompletedSurveys: false,
  isError: false,
  newsurveys: {
    count: 0,
    rows: [],
  },
  completedsurveys: {
    count: 0,
    rows: [],
  },
};

export interface IncreaseClickResponse {
  message: string;
  link: string;
}

export interface UpdateSurveyRequest {
  userId: string;
  surveyId: string;
  status: string;
}
