import { BaseStateProps } from "data/interface";

export interface FlexiCardsResponse {
  message: string;
  response: FlexiCardDetails;
}

export interface FlexiCardDetails {
  id: number;
}

export interface FlexiCardsStateProps extends BaseStateProps {
  isError: boolean;
  flexiCardDetails: FlexiCardDetails;
}

export const initialState: FlexiCardsStateProps = {
  isFetching: false,
  isError: false,
  flexiCardDetails: {
    id: -1,
  },
};
