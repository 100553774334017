import styled from "@emotion/styled";
import { css, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";
import Button from "components/button";

export const Wrapper = styled.div`
  padding: 1.8rem;

  ${mobile(css`
    padding: 1rem;
    padding-bottom: 2rem;
  `)}
`;

export const TableWrapper = styled.div`
  min-width: 750px;

  ${mobile(css`
    min-width: 0px;
  `)}
`;

export const ContentWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto !important;
  width: 100% !important;

  &::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 8px;
    cursor: pointer;
  }
`;

export const LoaderWrapper = styled.div`
  min-height: 150px;
`;

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 60px 20px 60px;
  gap: 24px;
  align-items: center;
`;

export const PurchaseSuccessText = styled(Typography)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
`;

export const GoToSurveysButton = styled(Button)`
  width: fit-content;
`;
