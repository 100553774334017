import { BaseStateProps } from "data/interface";

export interface GiftCardsRequest {
  pageSize: number;
  page: number;
}

export interface GiftCardsResponse {
  message: string;
  response: GiftCardDetails[];
}

export interface GiftCards {
  count: number;
  rows: GiftCardDetails[];
}

export interface GiftCardDetails {
  id: number;
  description: string;
  giftId: number;
  imageURL: string;
  inStock: true;
  terms: string;
  title: string;
  validity: number;
  coins: number;
  providerId: number;
  value: number;
  createdAt: string;
  updatedAt: string;
}

export interface GiftCardsStateProps extends BaseStateProps {
  isError: boolean;
  isFetched: boolean;
  giftcards: GiftCards;
  fetchedCards: boolean;
}

export const initialState: GiftCardsStateProps = {
  isFetching: false,
  isError: false,
  isFetched: false,
  fetchedCards: false,
  giftcards: {
    count: 0,
    rows: [],
  },
};
