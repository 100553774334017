export const Validators = [
  {
    title: "8+",
    description: "Characters",
    matcher: /(?=.{8,})\w+/g,
  },
  {
    title: "A",
    description: "Uppercase",
    matcher: /(?=.*[A-Z])\w+/g,
  },
  {
    title: "a",
    description: "Lowercase",
    matcher: /(?=.*[a-z])\w+/g,
  },
  {
    title: "0-9",
    description: "Number",
    matcher: /(?=.*[0-9])\w+/g,
  },
  {
    title: "!*#",
    description: "Sign",
    matcher: /(?=.*[!@#$%^&*])\w+/g,
  },
];
