import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_USER_ID, STORAGE_USERID } from "utils/constants";
import { saveToLocalStorage } from "utils/Utils";

import { initialState } from "./interface";

export const UserDetailsSlice = createSlice({
  name: "userdetails",
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.details = action.payload;
      saveToLocalStorage(STORAGE_USERID, action.payload.userId);
      saveToLocalStorage(STORAGE_USER_ID, action.payload.id);
    },
    error: (state, action) => {
      state.isFetching = false;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const { actions: UserDetailsActions } = UserDetailsSlice;

export default UserDetailsSlice.reducer;
