import { Divider } from "@mui/material";
import HeaderWithFilter from "components/headerwithfilter";
import CustomSnackbar from "components/snackbar";
import { Wrapper } from "containers/dashboard/styled.components";
import { State } from "data/reducers/state";
import { CartCountActions } from "data/slices/cartcount";
import { deactivateAccountApi } from "data/slices/deactivate/api";
import { DeactivateResponse } from "data/slices/deactivate/interface";
import { changePasswordApi } from "data/slices/resetpassword/api";
import { ResetPasswordResponse } from "data/slices/resetpassword/interface";
import { uploadFileApi } from "data/slices/upload/api";
import { UploadFileResponse } from "data/slices/upload/interface";
import { UserDetailsActions } from "data/slices/userdetails";
import { UserDetailsStateProps } from "data/slices/userdetails/interface";
import { updateProfileSettingDetailsApi } from "data/slices/userupdate/api";
import { UpdateUserDetailsResponse } from "data/slices/userupdate/interface";
import { WalletDetailsActions } from "data/slices/wallet";
import keys from "locale/keys";
import { isEmpty, isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ROUTES, SNACKBAR_TYPE } from "utils/constants";
import {
  getProfileDataToSave,
  getProfileDetails,
} from "utils/mappers/ProfileSettingsMapper";
import { logout } from "utils/Utils";
import { v4 as uuidv4 } from "uuid";
import PURE from "widgets/profilesettings";
import { ProfileDataToSave } from "widgets/profilesettings/interface";

const ProfileSettingsContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState({
    msg: "",
    type: SNACKBAR_TYPE.ERROR,
  });

  const { details, isFetching } = useSelector<State, UserDetailsStateProps>(
    (state) => state.userdetails,
  );

  const onOptionButtonClicked = () => {
    setUpdatingProfile(true);
  };

  const onBackHandler = () => {
    setUpdatingProfile(false);
  };

  const onShowSnackbarHandler = (msg: string, type: number) => {
    setShowSnackbar(true);
    setSnackbarMsg({ msg, type });
  };

  const onSaveProfileDetails = (
    profileDetails: ProfileDataToSave,
    profilePic: string,
  ) => {
    //if a profile pic is selected, upload it first and then send the upload file url in update profile details api
    if (profilePic) {
      uploadProfilePic(profileDetails, profilePic);
    } else {
      updateUserDetails(profileDetails);
    }
  };

  const uploadProfilePic = async (
    profileDetails: ProfileDataToSave,
    profilePic: string,
  ) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("image", profilePic);
    const response: UploadFileResponse = await uploadFileApi(formData);

    setIsLoading(false);
    if (isUndefined(response) || isUndefined(response.response)) {
      //failed to upload file
      console.log(`Failed to upload file..`);
    } else {
      profileDetails = { ...profileDetails, profilePic: response.response };
      updateUserDetails(profileDetails);
    }
  };

  const updateUserDetails = async (profileDetails: ProfileDataToSave) => {
    setIsLoading(true);
    const response: UpdateUserDetailsResponse =
      await updateProfileSettingDetailsApi(profileDetails);

    setIsLoading(false);
    if (isUndefined(response) || isEmpty(response)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (response.error) {
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.ERROR);
    } else {
      refreshUserDetails();
      onShowSnackbarHandler(response.message, SNACKBAR_TYPE.SUCCESS);
    }
  };

  const refreshUserDetails = () => {
    dispatch(UserDetailsActions.request());
  };

  const onPasswordChangeHandler = (
    oldPassowrd: string,
    newPassword: string,
  ) => {
    changePassword(oldPassowrd, newPassword);
  };

  const changePassword = async (oldPassowrd: string, newPassword: string) => {
    const changePasswordResponse: ResetPasswordResponse =
      await changePasswordApi({
        oldPassword: oldPassowrd,
        confirmPassword: newPassword,
      });

    if (isUndefined(changePasswordResponse)) {
      onShowSnackbarHandler(t(keys.generic_error), SNACKBAR_TYPE.ERROR);
    } else if (changePasswordResponse.error) {
      onShowSnackbarHandler(
        changePasswordResponse.message,
        SNACKBAR_TYPE.ERROR,
      );
    } else {
      onShowSnackbarHandler(
        changePasswordResponse?.message,
        SNACKBAR_TYPE.SUCCESS,
      );
    }
  };

  const onDeactivateAccount = () => {
    deactivateAccount();
  };

  const logoutUser = () => {
    logout();
    dispatch(UserDetailsActions.reset());
    dispatch(WalletDetailsActions.reset());
    dispatch(CartCountActions.reset());
    navigate(ROUTES.LOGIN, {
      replace: true,
    });
  };

  const deactivateAccount = async () => {
    setIsLoading(true);
    const response: DeactivateResponse = await deactivateAccountApi();

    setIsLoading(false);
    if (isUndefined(response) || isEmpty(response)) {
      onShowSnackbarHandler(t(keys.generic_error), 0);
    } else if (response.error) {
      onShowSnackbarHandler(response.message, 0);
    } else {
      logoutUser();
    }
  };

  //current details used to display to user
  const data = getProfileDetails(details);

  //this object will be updated and used to save
  const dataToSave = getProfileDataToSave(details);

  return (
    <>
      <HeaderWithFilter
        title={t(keys.profile_settings)}
        showFilter={false}
        showEdit={!updatingProfile}
        onOptionButtonClicked={onOptionButtonClicked}
      />
      <Divider />
      <Wrapper>
        <PURE
          key={uuidv4()}
          data={data}
          updateProfile={updatingProfile}
          onBackHandler={onBackHandler}
          dataToSave={dataToSave}
          onSaveProfileDetails={onSaveProfileDetails}
          isLoading={isLoading || isFetching}
          onDeactivateAccount={onDeactivateAccount}
          onPasswordChangeHandler={onPasswordChangeHandler}
          profilePicUrl={details.profilePic}
        />
      </Wrapper>
      <CustomSnackbar
        msg={snackbarMsg.msg}
        type={snackbarMsg.type}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
    </>
  );
};

export default ProfileSettingsContainer;
