import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

export const walletDetailsApi = () => {
  return axios.get(getBaseURL() + ENDPOINTS.WALLET_DETAILS, {
    headers: getHeaders(),
  });
};

export const getAchievementTierApi = async () => {
  try {
    const { data } = await axios.get(
      getBaseURL() + ENDPOINTS.ACHIEVEMENT_TIER_LIST,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};
