import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./interface";

export const CartCountSlice = createSlice({
  name: "cartcount",
  initialState: initialState,
  reducers: {
    request: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.cartcount = action.payload;
      state.isError = false;
    },
    error: (state, action) => {
      state.isFetching = false;
      state.cartcount = 0;
      state.isError = true;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

export const { actions: CartCountActions } = CartCountSlice;

export default CartCountSlice.reducer;
