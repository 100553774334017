import { PreloginProps } from "./interface";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { Wrapper } from "./styled.components";

const Prelogin = (props: PreloginProps) => {
  return (
    <Wrapper>
      <LeftPanel />
      <RightPanel {...props} />
    </Wrapper>
  );
};

export default Prelogin;
