import styled from "@emotion/styled";
import { Button, css, TextField, Typography } from "@mui/material";
import { mobile } from "commons/styled.components";

export const Wrapper = styled.div<{ showbackbutton: boolean }>`
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 2rem 0 ${(props) => (props.showbackbutton ? `1.5rem` : `2rem`)};
  gap: 12px;

  ${mobile(css`
    height: 60px;
    padding-left: 1rem;
  `)}
`;

export const Title = styled(Typography)`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
`;

export const FilterButton = styled(Button)`
  margin-left: auto;
  color: black;
  border-color: black;
  padding: 4px 12px;
  text-transform: none;
`;

export const SearchInput = styled(TextField)`
  margin-left: auto;
`;
