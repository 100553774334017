import styled from "@emotion/styled";
import { css } from "@mui/material";
import { mobile } from "commons/styled.components";

export const WalletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 0 8px;
`;

export const WalletImageWrapper = styled.img`
  ${mobile(css`
    width: 1.75rem;
  `)}
`;
