import CustomSnackbar from "components/snackbar";
import { State } from "data/reducers/state";
import { UserDetailsActions } from "data/slices/userdetails";
import { UserDetailsStateProps } from "data/slices/userdetails/interface";
import { updateUserDetailsApi } from "data/slices/userupdate/api";
import { UpdateUserDetailsResponse } from "data/slices/userupdate/interface";
import { WalletDetailsActions } from "data/slices/wallet";
import { WalletDetailsStateProps } from "data/slices/wallet/interface";
import keys from "locale/keys";
import { isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  DASHBOARD_DEFAULT_ROUTE,
  SNACKBAR_TYPE,
  STORAGE_IS_FIRST_LOGIN,
} from "utils/constants";
import {
  getDateFormat,
  getUserName,
  saveToLocalStorage,
  walletBalance,
} from "utils/Utils";
import PURE from "widgets/welcome";
import { UserDetailsProps } from "widgets/welcome/interface";

const WelcomeContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const { details, isFetching } = useSelector<State, UserDetailsStateProps>(
    (state) => state.userdetails,
  );

  const { isFetching: walletDetailsFetching, walletdetails } = useSelector<
    State,
    WalletDetailsStateProps
  >((state) => state.walletdetails);

  useEffect(() => {
    //dont call if user details already fetched, instead directly redirect to dashboard
    if (!details.dateOfBirth) {
      dispatch(UserDetailsActions.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!isEmpty(details?.dateOfBirth)) {
    navigate(DASHBOARD_DEFAULT_ROUTE, {
      replace: true,
    });
  }

  useEffect(() => {
    //dont call if wallet details already fetched
    if (!walletdetails.userId) {
      dispatch(WalletDetailsActions.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const navigateToDashboard = (details?: UserDetailsProps) => {
    if (!isEmpty(details.dob)) {
      updateUserDetails(details);
    } else {
      //directly navigated to dashboard
      navigate(DASHBOARD_DEFAULT_ROUTE, {
        replace: true,
      });
    }
  };

  const updateUserDetails = async (details: UserDetailsProps) => {
    setIsLoading(true);
    const response: UpdateUserDetailsResponse = await updateUserDetailsApi({
      dateOfBirth: getDateFormat(details.dob),
      gender: details.gender,
      postCode: details.address,
      maritalStatus: details.martialStatus,
    });

    setIsLoading(false);
    if (isUndefined(response)) {
      setSnackbarMsg(t(keys.generic_error));
    } else if (response.error) {
      setShowSnackbar(true);
      setSnackbarMsg(response.message);
    } else {
      saveToLocalStorage(STORAGE_IS_FIRST_LOGIN, "false");
      navigate(DASHBOARD_DEFAULT_ROUTE, {
        replace: true,
      });
    }
  };

  return (
    <>
      <PURE
        navigateToDashboard={navigateToDashboard}
        userName={getUserName(details?.firstName, details?.lastName)}
        walletBalance={walletBalance(walletdetails)}
        isLoading={isLoading || isFetching || walletDetailsFetching}
      />
      <CustomSnackbar
        msg={snackbarMsg}
        type={SNACKBAR_TYPE.ERROR}
        show={showSnackbar}
        dismiss={() => setShowSnackbar(false)}
      />
    </>
  );
};

export default WelcomeContainer;
