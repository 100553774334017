import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";

import { SupportTicketsAction } from ".";
import { getSupportTicketsApi } from "./api";

export default function* supportTickets(): SagaIterator {
  try {
    const response = yield call(getSupportTicketsApi);
    const { data } = response;
    yield put(SupportTicketsAction.success(data?.response?.rows));
  } catch (e) {
    yield put(SupportTicketsAction.error(e?.response?.data?.message));
  }
}
