import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const CustomPara = styled(Typography)<{ margintop?: string; color?: string }>`
  font-style: normal;
  margin: 0px;
  margin-top: ${(props) => props.margintop ?? 0};
  color: ${(props) => props.color ?? `#000000`};
`;

export const LargeLabel = styled(CustomPara)<{
  marginTop?: string;
  color?: string;
}>`
  font-size: 1.75rem;
  font-weight: 500;
`;

export const MediumLabel = styled(CustomPara)<{ marginTop?: string }>`
  font-size: 1rem;
  font-weight: 400;
`;

export const NormalLabel = styled(CustomPara)<{ marginTop?: string }>`
  font-size: 0.88rem;
  font-weight: 400;
`;

export const NormalBoldLabel = styled(CustomPara)<{ marginTop?: string }>`
  font-size: 0.88rem;
  font-weight: 500;
`;
