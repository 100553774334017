import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";
import { getHeaders } from "data/headers";

import {
  CreateSupportTicketRequest,
  UpdateSupportTicketRequest,
} from "./interface";

export const createSupportTicketApi = async (
  request: CreateSupportTicketRequest,
) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.SUPPORT_CREATE,
      request,
      {
        headers: getHeaders(),
      },
    );

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} }; //manually added error for error snackbar validation
  }
};

export const updateSupportTicketStatusApi = async (
  request: UpdateSupportTicketRequest,
) => {
  try {
    const url = getBaseURL() + ENDPOINTS.SUPPORT_UPDATE_STATUS + request.id;
    const { data } = await axios.patch(url, request, {
      headers: getHeaders(),
    });

    return data;
  } catch (e) {
    return { message: e.response?.data?.message, error: {} };
  }
};

export const getSupportTicketsApi = () => {
  return axios.get(getBaseURL() + ENDPOINTS.SUPPORT_LIST, {
    headers: getHeaders(),
  });
};
