import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";

export const ModalContent = styled.div`
  position: relative;
  overflowy: auto;
  background-color: #fff;
  padding: 24px 48px 48px 48px;
  border-radius: 10px;
  background: #fcfcfc;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;

export const ModalCloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 12px;
`;

export const DialogFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

export const FieldTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: #000000;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: end;
`;

export const SupportListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
`;

export const IssuesTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`;
