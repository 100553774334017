import React from "react";

import FullScreenLoader from "./loader";

const withLoader = <P extends object>(Component: React.ComponentType<P>) => {
  const withLoadingComponent = ({ isLoading = false, ...props }) => {
    return (
      <>
        <Component {...(props as P)} />
        {isLoading && <FullScreenLoader />}
      </>
    );
  };
  return withLoadingComponent;
};

export default withLoader;
