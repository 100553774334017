import { Grid, TablePagination } from "@mui/material";
import { TablePaginationActions } from "commons";
import { PaginationWrapper } from "commons/styled.components";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import { isUndefined } from "lodash";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  COUPON_ROWS_AFTER_SHOW_MORE,
  COUPON_ROWS_DEFAULT,
  COUPONS_PER_ROW,
  DEFAULT_PAGE,
  DEFAULT_REDEEM_ROWS_PER_PAGE,
  REDEEM_ROW_OPTIONS_PER_PAGE,
} from "utils/constants";

import CouponDetails from "./CouponDetails";
import { CouponProps, RedeemPointsProps } from "./interface";
import { ShowMoreText, ShowMoreWrapper } from "./styled.components";
import { getCoupons } from "./utils";

const RedeemPoints = (props: RedeemPointsProps) => {
  const { coupons, isError, isFetching } = props;
  const { t } = useTranslation();
  const [showMoreSelected, setShowMoreSelected] = useState(false);
  const [showingMoreCoupons, setShowingMoreCoupons] = useState(false);
  const [couponsToDisplay, setCouponsToDisplay] = useState<
    Map<string, CouponProps[]>
  >(new Map());
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_REDEEM_ROWS_PER_PAGE);
  const [couponsArray, setCouponsArray] = useState<[string, CouponProps[]][]>();

  const isEmpty = isUndefined(coupons) || (coupons && coupons.size === 0);

  window.scrollTo({ top: 0, behavior: "instant" });

  //convert only once to array and re-use this
  useEffect(() => {
    if (!isEmpty) {
      const arr = Array.from(coupons.entries());
      setCouponsArray(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty]);

  useEffect(() => {
    if (!isEmpty) {
      const filteredCoupons: Map<string, CouponProps[]> = new Map();

      //first show 2 rows of coupon (each row 3 coupons) and show more
      //once show is clicked then show 4 more rows and pagination option
      var firstPageItems = COUPON_ROWS_DEFAULT * COUPONS_PER_ROW;
      if (showMoreSelected) {
        firstPageItems =
          (COUPON_ROWS_DEFAULT + COUPON_ROWS_AFTER_SHOW_MORE) * COUPONS_PER_ROW;
      }

      const values = Array.from(coupons.entries()).slice(0, firstPageItems);
      values.forEach(([key, value]) => {
        filteredCoupons.set(key, value);
      });

      setCouponsToDisplay(filteredCoupons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty, showMoreSelected, coupons]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    setCouponsToDisplay(getCoupons(couponsArray, rowsPerPage, newPage));
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCouponsToDisplay(getCoupons(couponsArray, newRowsPerPage, page));
  };

  if (isError || isFetching || isEmpty) {
    return (
      <LoadingEmptyError
        isEmpty={isEmpty}
        isError={isError}
        isLoading={isFetching}
        largesize={true}
      />
    );
  }

  const shouldShowMore =
    !showMoreSelected &&
    //isEqual(pageNumber, 0) &&
    coupons.size > COUPON_ROWS_DEFAULT * COUPONS_PER_ROW;

  return (
    <>
      <Grid container spacing="2rem">
        {Array.from(couponsToDisplay).map(([key, value]) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} key={key}>
              <CouponDetails imageUrl={key} coupons={value} />
            </Grid>
          );
        })}
      </Grid>
      {shouldShowMore && (
        <ShowMoreWrapper>
          <ShowMoreText
            onClick={() => {
              setShowMoreSelected(true);
              setShowingMoreCoupons(true);
            }}
          >
            {t(keys.show_more)}
          </ShowMoreText>
        </ShowMoreWrapper>
      )}
      {showingMoreCoupons && (
        <PaginationWrapper data-testid="pagination-wrapper-orders">
          <TablePagination
            rowsPerPageOptions={REDEEM_ROW_OPTIONS_PER_PAGE}
            colSpan={3}
            count={coupons.size}
            labelRowsPerPage={t(keys.items_per_page)}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{
              borderBottom: "none",
            }}
          />
        </PaginationWrapper>
      )}
    </>
  );
};

const PURE = React.memo(withLoader(RedeemPoints));
export default PURE;
