import i18n from "i18n";
import keys from "locale/keys";
import { DASHBOARD_ROUTES, ROUTES } from "utils/constants";

import Logout from "../../assets/icons/Logout.svg";
import menu_shopping_cart from "../../assets/icons/menu_shopping_cart.svg";
import Orders from "../../assets/icons/sidedrawer/Orders.svg";
import Redeem from "../../assets/icons/sidedrawer/redeem.svg";
import Support from "../../assets/icons/sidedrawer/Support.svg";
import Tasks from "../../assets/icons/sidedrawer/tasks.svg";
import Wallet from "../../assets/icons/sidedrawer/wallet.svg";
import User from "../../assets/icons/User.svg";

export const SIDEDRAWER_ITEMS = [
  {
    title: i18n.t(keys.answer_surveys),
    icon: Tasks,
    route: DASHBOARD_ROUTES.TASKS,
    enabled: true,
  },
  {
    title: i18n.t(keys.my_wallet),
    icon: Wallet,
    route: DASHBOARD_ROUTES.MY_WALLET,
    enabled: true,
  },
  {
    title: i18n.t(keys.redeem_points),
    icon: Redeem,
    route: DASHBOARD_ROUTES.REDEEM,
    enabled: true,
  },
  {
    title: i18n.t(keys.my_orders),
    icon: Orders,
    route: DASHBOARD_ROUTES.MY_ORDERS,
    enabled: true,
  },
  /* {
    title: i18n.t(keys.refer_users),
    icon: Inviteusers,
    route: DASHBOARD_ROUTES.REFER_USERS,
    enabled: true,
  }, */
  /* {
    title: "Notifications",
    icon: Notifications,
    route: DASHBOARD_ROUTES.NOTIFICATIONS,
    enabled: false,
  }, */
];

export const SIDEDRAWER_FOOTER_ITEMS = [
  {
    title: i18n.t(keys.support),
    icon: Support,
    route: DASHBOARD_ROUTES.SUPPORT,
    enabled: true,
  },
];

export const MENU_ITEMS = [
  ...SIDEDRAWER_ITEMS,
  {
    title: i18n.t(keys.cart),
    icon: menu_shopping_cart,
    route: DASHBOARD_ROUTES.CART,
    enabled: true,
  },
  {
    title: i18n.t(keys.profile_settings),
    icon: User,
    route: DASHBOARD_ROUTES.PROFILE_SETTINGS,
    enabled: true,
  },
  {
    title: i18n.t(keys.logout),
    icon: Logout,
    route: ROUTES.LOGIN,
    enabled: true,
  },
];
