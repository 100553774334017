import { isEqual } from "lodash";

import { BUTTON_SIZE } from "./interface";

export const getFontSize = (buttonSize: number) => {
  if (isEqual(buttonSize, BUTTON_SIZE.LARGE)) {
    return "1.13rem";
  } else if (isEqual(buttonSize, BUTTON_SIZE.MEDIUM)) {
    return "0.86rem";
  } else {
    return "0.75rem";
  }
};

export const getPadding = (buttonSize: number) => {
  if (isEqual(buttonSize, BUTTON_SIZE.LARGE)) {
    return "0.38rem 1rem";
  } else if (isEqual(buttonSize, BUTTON_SIZE.MEDIUM)) {
    return "0.38rem 1rem";
  } else {
    return "0.25rem 0.5rem";
  }
};
