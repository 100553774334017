import { GenericTableProps } from "commons/interface";
import {
  AchievementTierDetails,
  WalletResponse,
} from "data/slices/wallet/interface";

export interface WalletProps {
  isFetching: boolean;
  walletDetails?: WalletResponse;
  completedSurveys: number;
  onShowEarningsLogHandler: () => void;
  isGraphsLoading?: boolean;
  chartData: ChartDataField[];
  isAchievementsLoading?: boolean;
  achievementTierDetails?: AchievementTierDetails[];
  updateGraphsData: (filterType: string) => void;
}

export interface BalanceItemProps {
  balanceType: number;
  points: number;
  onClickHandler?: () => void;
}

export interface EarningsLogProps {
  earningsToDisplay: GenericTableProps;
  recordsCount: number;
  isLoading?: boolean;
  isError?: boolean;
  fetchCoinsHistory: (page: number, pageSize: number) => void;
}

export interface ChartProps {
  chartData: ChartDataField[];
}

export interface ChartDataField {
  label: string;
  coins: number;
  tooltipDate: string;
}

export const BALANCE_TYPE = {
  BALANCE: 0,
  EARNINGS: 1,
  REDEEMED: 2,
};

export interface RewardsPopoverProps {
  achievementTierDetails?: AchievementTierDetails[];
}
