import { BaseStateProps } from "data/interface";

export interface CoinsHistoryRequest {
  status: string;
  pageSize: number;
  page: number;
}

export interface CoinsHistoryResponse {
  message: string;
  response: Response;
}

export interface Response {
  count: number;
  rows: CoinsHistoryItem[];
}

export interface CoinsHistoryItem {
  id: number;
  transactionId: string;
  coins: number;
  type: string;
  transactionStatus: string;
  userId: number;
  surveyId: number;
  masterSurvay?: MasterSurveyDetails;
  masterGiftCard?: MasterGiftCardDetails;
  status: string;
  createdAt: string;
}

interface MasterSurveyDetails {
  prjId: number;
  surveyName: string;
  category: string;
  surveyNumber: number;
}

export interface MasterGiftCardDetails {
  title: string;
  value: number;
}

export interface CoinsHistoryStateProps extends BaseStateProps {
  coinshistory: Response;
  isError: boolean;
}

export const initialState: CoinsHistoryStateProps = {
  coinshistory: {
    count: 0,
    rows: [],
  },
  isError: false,
  isFetching: false,
};

export interface CoinsHistoryFilterRequest {
  status: string;
  filterBy: string;
}

export interface CoinsHistoryFilterResponse {
  message: string;
  response: FiltersData;
}

export interface FiltersData {
  rows: FilterData[];
}

export interface FilterData {
  createdAt: string;
  coins: number;
}
