import { InputFieldInputStyle } from "commons/styled.components";
import Button from "components/button";
import Label from "components/label";
import PasswordValidator from "components/passwordvalidation";
import PasswordTextField from "components/textfield/PasswordTextField";
import withLoader from "hoc/loader";
import keys from "locale/keys";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "utils/colors";

import { ChangePasswordValidationModel } from "../interface";
import {
  BaseForm,
  InputFieldWrapper,
  LoginWrapper,
} from "../styled.components";
import {
  validateChangePassword,
  validatePasswordForStrength,
  validateSimpleInput,
} from "../utils";
import { ChangePasswordProps } from "./interface";

const ChangePassword = (props: ChangePasswordProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordStrengthValidated, setPasswordStrengthValidated] =
    React.useState(false);

  const [validationModel, setValidationModel] =
    React.useState<ChangePasswordValidationModel>({
      isValidPassword: true,
      isValidConfirmPassword: true,
    });

  const passwordValidation = () => {
    const model = validatePasswordForStrength(
      password,
      passwordStrengthValidated,
    );
    setValidationModel({
      ...validationModel,
      isValidPassword: model.isValid,
      passwordMessage: model.message,
    });
  };

  const confirmPasswordValidation = () => {
    const model = validateSimpleInput(
      confirmPassword,
      keys.please_enter_confirm_password,
    );
    setValidationModel({
      ...validationModel,
      isValidConfirmPassword: model.isValid,
      confirmPasswordMessage: model.message,
    });
  };

  const onChangeClickedHandler = (event: any) => {
    event.preventDefault();
    const model = validateChangePassword(
      password,
      confirmPassword,
      passwordStrengthValidated,
    );
    setValidationModel(model);
    if (model.isValidPassword && model.isValidConfirmPassword) {
      props.onChangePasswordHandler(password);
    }
  };

  if (props.isPasswordChanged) {
    return (
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.successful)}
        </Label>
        <Label type="medium" color={COLORS.LOGIN_SECONDARY}>
          {t(keys.successfully_reset)}
        </Label>
        <Button onClickHandler={props.onLoginHandler}>{t(keys.login)}</Button>
      </LoginWrapper>
    );
  }

  return (
    <BaseForm onSubmit={onChangeClickedHandler}>
      <LoginWrapper>
        <Label type="large" color={COLORS.LOGIN_PRIMARY}>
          {t(keys.change_password)}
        </Label>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.confirm_password)}
          </Label>
          <PasswordTextField
            placeholder={t(keys.enter_password)}
            error={!validationModel.isValidPassword}
            onInput={(e: any) => {
              setPassword(e.target.value);
            }}
            onBlur={() => passwordValidation()}
            helperText={validationModel?.passwordMessage}
            inputProps={InputFieldInputStyle(validationModel?.isValidPassword)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <Label type="normalbold" color={COLORS.LOGIN_PRIMARY}>
            {t(keys.confirm_password)}
          </Label>
          <PasswordTextField
            placeholder={t(keys.enter_password)}
            error={!validationModel.isValidConfirmPassword}
            onInput={(e: any) => {
              setConfirmPassword(e.target.value);
            }}
            onBlur={() => confirmPasswordValidation()}
            helperText={validationModel?.confirmPasswordMessage}
            inputProps={InputFieldInputStyle(
              validationModel?.isValidConfirmPassword,
            )}
          />
        </InputFieldWrapper>
        <PasswordValidator
          password={password}
          setPasswordStrengthValidated={setPasswordStrengthValidated}
        />
        <Button onClickHandler={onChangeClickedHandler} type="submit">
          {t(keys.change)}
        </Button>
      </LoginWrapper>
    </BaseForm>
  );
};

const PURE = withLoader(ChangePassword);
export default PURE;
