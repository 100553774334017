import InfoIcon from "@mui/icons-material/Info";
import LoadingEmptyError from "components/LoaderEmptyErrorState";
import { surveyUpdate } from "data/slices/surveylist/api";
import keys from "locale/keys";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { STORAGE_USER_ID, SURVEY_STATUS } from "utils/constants";
import { getFromLocalStorage } from "utils/Utils";

import AppLogo from "../../assets/icons/ApplogoBlack.svg";
import { AppLogoImg, Message, Wrapper } from "./styled.components";
import { getRequestPayload } from "./utils";

//these are specific to dynata
const SurveyInfoRedirection = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    submitSurveyStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSurveyStatus = async () => {
    setIsLoading(true);

    const userId = getFromLocalStorage(STORAGE_USER_ID);
    const request = getRequestPayload(userId, params);
    if (request) {
      //explicitly set the status as failed
      request.status = SURVEY_STATUS.FAILED;
      await surveyUpdate(request);
    }

    setIsLoading(false);
  };

  return (
    <Wrapper>
      <AppLogoImg src={AppLogo} alt="app logo" />
      <InfoIcon style={{ color: "gray", width: "4rem", height: "4rem" }} />
      <Message>{t(keys.survey_info_msg)}</Message>
      {isLoading && (
        <div>
          <LoadingEmptyError isLoading={true} isError={false} isEmpty={false} />
        </div>
      )}
    </Wrapper>
  );
};

export default SurveyInfoRedirection;
