import { BaseStateProps } from "data/interface";

export interface UserDetailsResponse {
  message: string;
  response: UserDetails;
}

export interface UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  userGuid: string;
  userId: string;
  dateOfBirth: string;
  resetPasswordToken: string;
  resetPasswordExpires: string;
  address: string;
  gender: string;
  isDeleted: boolean;
  refreshToken: string;
  otp: string;
  isEmailVerified: boolean;
  role: string;
  createdAt: string;
  updatedAt: string;
  ethnicity: string;
  postCode: string;
  mobile: string;
  maritalStatus: string;
  profilePic: string;
}

export interface UserDetailsStateProps extends BaseStateProps {
  details: UserDetails;
}

export const initialState: UserDetailsStateProps = {
  isFetching: false,
  details: {
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    userGuid: "",
    userId: "",
    dateOfBirth: "",
    resetPasswordToken: "",
    resetPasswordExpires: "",
    address: "",
    gender: "",
    isDeleted: false,
    refreshToken: "",
    otp: "",
    isEmailVerified: false,
    role: "",
    createdAt: "",
    updatedAt: "",
    ethnicity: "",
    postCode: "",
    mobile: "",
    maritalStatus: "",
    profilePic: "",
  },
};
