import { Snackbar, useMediaQuery, useTheme } from "@mui/material";
import { StyledAlertCommon } from "commons/styled.components";
import {
  SNACKBAR_AUTO_DISMISS_DURATION_MS,
  SNACKBAR_TYPE,
} from "utils/constants";

import { SnackbarProps } from "./interface";

const CustomSnackbar = (props: SnackbarProps) => {
  const { msg, type, show, dismiss } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={SNACKBAR_AUTO_DISMISS_DURATION_MS}
      open={show}
      onClose={dismiss}
      sx={{
        width: isMobile ? "70%" : "50%",
        marginLeft: isMobile ? "15%" : "0%",
      }}
    >
      <StyledAlertCommon
        onClose={dismiss}
        severity="success"
        variant="filled"
        isSuccess={type === SNACKBAR_TYPE.SUCCESS}
      >
        {msg}
      </StyledAlertCommon>
    </Snackbar>
  );
};

export default CustomSnackbar;
