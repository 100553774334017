import { isSmallDevice$ } from "common.service";
import Profile from "components/profile";
import WalletBalance from "components/walletbalance";
import { isEqual } from "lodash";
import React from "react";
import { getStringWithDefaultValue } from "utils/Utils";

import survey_logo from "../../assets/icons/survey_logo.png";
import surveyLogo from "../../assets/icons/surveyLogo.svg";
import { WELCOME_STEPS_COUNT } from "./Constants";
import { WelcomeHeaderProps } from "./interface";
import {
  HeaderWrapper,
  ImageContentWrapper,
  LogoImageWrapper,
  ProfileWrapper,
  Step,
  StepsWrapper,
} from "./styled.components";

export const WelcomeHeader = (props: WelcomeHeaderProps) => {
  const { step, userName, walletBalance } = props;
  const [isSmallDevice, setSmallDevice] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (
      (
        window.performance.getEntriesByType(
          "navigation",
        )[0] as PerformanceNavigationTiming
      ).type === "reload"
    ) {
      handleResize();
    }
    const subscription = isSmallDevice$.subscribe(setSmallDevice);
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);

  const handleResize = () => {
    if (window.innerWidth > 768) {
      setSmallDevice(false);
    } else {
      setSmallDevice(true);
    }
  };

  const Steps = () => {
    const arr = [];
    for (let i = 0; i < WELCOME_STEPS_COUNT; i++) {
      arr.push(
        <Step
          key={i}
          isSelected={!(step <= i)}
          isFirstStep={isEqual(i, 0)}
          isLastStep={isEqual(i, WELCOME_STEPS_COUNT - 1)}
        />,
      );
    }
    return arr;
  };

  return (
    <HeaderWrapper data-testid="welcome-header-wrapper">
      {!isSmallDevice && (
        <>
          <LogoImageWrapper src={surveyLogo} alt="logo" />
          <StepsWrapper>{Steps()}</StepsWrapper>

          <ProfileWrapper>
            <WalletBalance balance={`${walletBalance}`} />
            <Profile
              name={getStringWithDefaultValue(userName)}
              showMenuOptions={false}
            />
          </ProfileWrapper>
        </>
      )}
      {isSmallDevice && (
        <>
          <ImageContentWrapper>
            <img
              src={survey_logo}
              alt="logo"
              style={{ width: "2.6rem", height: "2.2rem" }}
            />
            <ProfileWrapper>
              <WalletBalance balance={`${walletBalance}`} />
              <Profile
                name={getStringWithDefaultValue(userName)}
                showMenuOptions={false}
              />
            </ProfileWrapper>
          </ImageContentWrapper>
          <StepsWrapper>{Steps()}</StepsWrapper>
        </>
      )}
    </HeaderWrapper>
  );
};

export default WelcomeHeader;
