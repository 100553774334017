import axios from "axios";
import { ENDPOINTS, getBaseURL } from "data/endpoints";

export const uploadFileApi = async (formData: any) => {
  try {
    const { data } = await axios.post(
      getBaseURL() + ENDPOINTS.UPLOAD,
      formData,
    );

    return data;
  } catch (e) {
    return e.response?.data;
  }
};
